import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Config } from "../app.config";
import { UsuarioService } from '../services/usuario.service';

@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.css']
})
export class RecuperarPassComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    token_min:number = 0; token_max:number = 1; contrasena_min: number = 1;
    recuperarForm: FormGroup;
    resetearForm: FormGroup;
    validar_token: boolean = false;
    reseteado: boolean = false;
    state: any = false;
    loading:boolean = false

    constructor(public config: Config, private formBuilder: FormBuilder, public usuarioService: UsuarioService) { }

    ngOnInit() {
        this.configuraciones = this.config.data; this.token_min = this.configuraciones.general.token_min; this.token_max = this.configuraciones.general.token_max; this.contrasena_min = this.configuraciones.general.contrasena_min;
        this.recuperarForm = this.formBuilder.group({
            email: ['', Validators.required ]
        });

        this.resetearForm = this.formBuilder.group({
            token_correo: ['', Validators.required ],
            contrasena: ['', Validators.required ],
            confirmacion_contrasena: ['', Validators.required ]
        });
    }

    onSubmit(recuperarForm) {
        this.loading = true;
        this.usuarioService.postRecuperarContrasena(recuperarForm)
            .subscribe(data => {
                if(data) {
                    if(data.mensaje || data.error) {
                        this.loading = false;
                        this.validar_token = true;
                        this.state = [{state: "error", origen: "recuperar", data: [(data.mensaje ? data.mensaje : data.error.mensaje)]}];    
                    } else if(data.data) {
                        this.loading = false;
                        this.validar_token = true;
                        localStorage.setItem('tipo_token', 'Bearer');
                        localStorage.setItem('api_token', data.data.token);
                    }
                } else {
                    this.loading = false;
                    this.validar_token = true;
                    this.state = [{state: "error", origen: "recuperar"}];
                }
            });
    }

    onSubmitResetear(resetearForm) {
        this.loading = true;
        this.usuarioService.postRecuperarModificarContrasena(resetearForm)
            .subscribe(data => {
                if(data) {
                    if(data.mensaje) {
                        this.loading = false;
                        this.reseteado = true;
                        this.state = [{state: "error", origen: "recuperar", data: [data.mensaje]}];    
                    } else if(data.data) {
                        this.loading = false;
                        this.reseteado = true;
                        this.state = [{state: "ok", origen: "recuperar"}];
                    }
                } else {
                    this.loading = false;
                    this.reseteado = true;
                    this.state = [{state: "error", origen: "recuperar"}];
                }
            });
    }

    cerrarState() {
        this.state = false; this.validar_token = false; this.reseteado = false;
    }

}