import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { AuthService } from './auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    session: boolean = false;
    usuario: any;
    url_base:string = "/busqueda_estaciones";
    
    constructor(private formBuilder: FormBuilder, public translate: TranslateService, public authService: AuthService, public router: Router) {
        translate.addLangs(['en', 'es']);
        translate.setDefaultLang('es');
        if(localStorage.getItem('api_token')) {
            this.usuario = {nombres: localStorage.getItem('nombres_ciudadano_bel'), apellidos: localStorage.getItem('apellidos_ciudadano_bel')};
        }
        
    }

    revisarCabecera() {
        this.usuario = {nombres: localStorage.getItem('nombres_ciudadano_bel'), apellidos: localStorage.getItem('apellidos_ciudadano_bel')};
    }

    onDeactivate(componentReference) {
        this.session = this.authService.isLoggedIn;
    }
}
