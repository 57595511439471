import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Config } from "../app.config";

import { AuthService } from '../auth/auth.service';
import { UsuarioService } from '../services/usuario.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    loginForm: FormGroup;
    state: any;
    loading:boolean = false;
    constructor(public config: Config, private formBuilder: FormBuilder, public authService: AuthService, public router: Router, public usuarioService: UsuarioService) { }

    ngOnInit() {
        localStorage.removeItem('tipo_token');
        localStorage.removeItem('api_token');
        localStorage.removeItem('apellidos_ciudadano_bel');
        localStorage.removeItem('nombres_ciudadano_bel');
        this.authService.isLoggedIn = false;
        this.eventoPadreComponent.emit();
        this.configuraciones = this.config.data;

        this.loginForm = this.formBuilder.group({
            correo: '',
            password: ''
        });
    }

    onSubmit(loginForm) {
        this.loading = true;
        this.usuarioService.postLogin(loginForm)
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.state = [{state: "error", origen: "login", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        localStorage.setItem('tipo_token', usuario.data.tipo_token);
                        localStorage.setItem('api_token', usuario.data.api_token);
                        this.getUsuario();
                    }
                } else {
                    this.loading = false;
                    this.state = [{state: "error", origen: "login"}];
                }
            });
    }

    private getUsuario() {        
        this.usuarioService.getUsuario()
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.state = [{state: "error", origen: "login", data: [usuario.mensaje]}];  
                        this.loading = false;  
                    } else if(usuario.data) {
                        localStorage.setItem('nombres_ciudadano_bel', usuario.data.nombres);
                        localStorage.setItem('apellidos_ciudadano_bel', usuario.data.apellidos);
                        this.authService.login();
                        this.router.navigateByUrl("/busqueda_estaciones");                        
                    }
                } else {
                    this.state = [{state: "error", origen: "login"}];
                    this.loading = false;
                }
            });
    }

    cerrarState() {
        this.state = false;
    }

    claveUnicaRedirect() {
        let state = this.usuarioService.generarState(this.configuraciones.claveunica.state_largo);
        let urlLoginClaveUnica = this.configuraciones.claveunica.base+encodeURIComponent("?client_id="+this.configuraciones.claveunica.client_id+"&redirect_uri="+this.configuraciones.claveunica.redirect_uri+"&response_type="+this.configuraciones.claveunica.response_type+"&scope="+this.configuraciones.claveunica.scope+"&state="+state);
        window.location.href = urlLoginClaveUnica;
    }
}
