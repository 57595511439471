import { Component, OnInit, Inject } from '@angular/core';
import { ReporteService } from '../services/reporte.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ReporteVariacionPrecio } from '../dto/ReporteVariacionPrecio.dto';
import { DatePipe } from '@angular/common';
//import * as moment from 'moment';
import moment from 'moment';

@Component({
  selector: 'app-reporte-variacion-precios-detalle',
  templateUrl: './reporte-variacion-precios-detalle.component.html',
  styleUrls: ['./reporte-variacion-precios-detalle.component.css']
})
export class ReporteVariacionPreciosDetalleComponent implements OnInit {

  public reporte: ReporteVariacionPrecio[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  now = new Date();
  fechaReporte: Date;
  public loading: boolean;

  constructor(@Inject(ReporteService) private  reporteService: ReporteService,
              private _route: ActivatedRoute, public datepipe: DatePipe) { }

  async ngOnInit() {
    this.loading = true;

    this.dtOptions = {
      paging: true,
      searching: true,
      columnDefs: [ { orderable: false, targets: '_all' }],   // Deshabilitar orden para todas las columnas
      responsive: true,   // Use this attribute to enable the responsive extension
      info: false,
      language: {
          zeroRecords: 'Sin datos',
          emptyTable: 'Sin datos',
          search: 'Buscar',
          lengthMenu: 'Mostrar _MENU_ registros',
          infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
          paginate: {
            first:    'Primero',
            last:     'Último',
            next:     'Siguiente',
            previous: 'Anterior'
        },
      }
    };
    await this.getReporte();
    this.loading = false;
  }

  async getReporte() {
    this.fechaReporte = new Date(this._route.snapshot.paramMap.get('fecha_reporte'));
    this.reporte = await this.reporteService
      .datosReporteVariacionPrecio(this.datepipe.transform(this.fechaReporte, 'yy-MM-dd HH:mm:ss'))
      .toPromise();
    this.dtTrigger.next();
  }

  public formatDate(date) {
    return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
  }
}
