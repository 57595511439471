import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ReporteService } from '../services/reporte.service';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-reporte-variacion-precios',
  templateUrl: './reporte-variacion-precios.component.html',
  styleUrls: ['./reporte-variacion-precios.component.css']
})
export class ReporteVariacionPreciosComponent implements OnInit, OnDestroy {

  public reportes;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  now = new Date();
  public loading: boolean;

  constructor(@Inject(ReporteService) private  reporteService: ReporteService) { }

  async ngOnInit() {
    this.loading = true;

    this.dtOptions = {
      ordering: null,
      paging: true,
      searching: true,
      columnDefs: [ { orderable: false, targets: '_all' }],   // Deshabilitar orden para todas las columnas
      responsive: true,   // Use this attribute to enable the responsive extension
      info: false,
      language: {
          zeroRecords: 'Sin datos',
          emptyTable: 'Sin datos',
          search: 'Buscar',
          lengthMenu: 'Mostrar _MENU_ registros',
          infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
          paginate: {
            first:    'Primero',
            last:     'Último',
            next:     'Siguiente',
            previous: 'Anterior'
        },
      }
    };
    await this.getReportes();
    this.loading = false;
  }

  async getReportes() {
    this.reportes = await this.reporteService.fechasReporteVariacionPrecioFavoritas().toPromise();
    this.dtTrigger.next();
  }

  downloadCsv(fechaReporte){
    this.reporteService.descargaReporteVariacionPrecio(fechaReporte);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
