import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';
import { ClaveUnicaComponent } from './clave-unica/clave-unica.component';
import { RedireccionarComponent } from './clave-unica/redireccionar/redireccionar.component';
import { MisDatosComponent } from './mis-datos/mis-datos.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BusquedaEstacionesComponent } from './busqueda-estaciones/busqueda-estaciones.component';
import { EstacionComponent } from './busqueda-estaciones/estacion/estacion.component';
import { BusquedaElectrolinerasComponent } from './busqueda-electrolineras/busqueda-electrolineras.component';
import { EstacionesFavoritasComponent } from './estaciones-favoritas/estaciones-favoritas.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { GlosarioComponent } from './glosario/glosario.component';
import { ComunalComponent } from './reportes/comunal/comunal.component';
import { RegionalComponent } from './reportes/regional/regional.component';
import { ZonalComponent } from './reportes/zonal/zonal.component';
import { ReporteVariacionPreciosComponent } from './reporte-variacion-precios/reporte-variacion-precios.component';
import { ReporteVariacionPreciosDetalleComponent } from './reporte-variacion-precios-detalle/reporte-variacion-precios-detalle.component';

const routes: Routes = [
    { path: '', redirectTo: '/busqueda_estaciones', pathMatch: 'full'},
    { path: 'login', component: LoginComponent },
    { path: 'registro', component: RegistroComponent },
    { path: 'recuperar', component: RecuperarPassComponent },
    { path: 'claveunica', component: RedireccionarComponent },
    { path: 'clave_unica_registro', component: ClaveUnicaComponent },
    { path: 'mis_datos', component: MisDatosComponent, canActivate: [AuthGuard] },
    { path: 'busqueda_estaciones', component: BusquedaEstacionesComponent },
    { path: 'busqueda_estaciones/:id/:combustible', component: BusquedaEstacionesComponent },
    { path: 'busqueda_estaciones/estacion', component: EstacionComponent},
    { path: 'busqueda_electrolineras', component: BusquedaElectrolinerasComponent },
    { path: 'estaciones_favoritas', component: EstacionesFavoritasComponent, canActivate: [AuthGuard] },
    { path: 'politica', component: PoliticaPrivacidadComponent },
    { path: 'glosario/:id', component: GlosarioComponent },
    { path: 'reporte_comunal', component: ComunalComponent },
    { path: 'reporte_comunal/:combustible_id', component: ComunalComponent },
    { path: 'reporte_comunal/:combustible_id/:zona_id', component: ComunalComponent },
    { path: 'reporte_comunal/:combustible_id/:zona_id/:region_id', component: ComunalComponent },
    { path: 'reporte_comunal/:combustible_id/:zona_id/:region_id/:orden', component: ComunalComponent },
    { path: 'reporte_regional', component: RegionalComponent },
    { path: 'reporte_regional/:combustible_id', component: RegionalComponent },
    { path: 'reporte_regional/:combustible_id/:region_id', component: RegionalComponent },
    { path: 'reporte_regional/:combustible_id/:region_id/:orden', component: RegionalComponent },
    { path: 'reporte_nacional', component: ZonalComponent },
    { path: 'reporte_variacion_precios', component: ReporteVariacionPreciosComponent },
    { path: 'reporte_variacion_precios_detalle/:fecha_reporte', component: ReporteVariacionPreciosDetalleComponent },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
