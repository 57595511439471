import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { EstacionService } from '../services/estacion.service';
import { SocialService } from "ngx-social-button";
import * as mapboxgl from 'mapbox-gl';
import { Config } from "../app.config";
import * as Highcharts from 'highcharts';
declare var window: any;
declare var require: any;

let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-modal-detalle-estacion',
  templateUrl: './modal-detalle-estacion.component.html',
  styleUrls: ['./modal-detalle-estacion.component.css']
})
export class ModalDetalleEstacionComponent implements OnInit {
  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
  @Input() estacion:any;
//   @Input() combustible:any;
  @Input() solo_puntos_electricos = 0;
  @Input() solo_favoritos = 0;
  @Input() marcas:any;
  @Output() marcarLikeDislikeEnListado: EventEmitter<any> = new EventEmitter();
  @Output() modalClose = new EventEmitter<void>();

  modalDetalleEstacion: any;
  map: mapboxgl.Map;
  configuraciones;
  latitud; longitud;
  style = '';
  mapZoom = 0;

  iconFavorito = ['fas', 'star']; statusFavorito: boolean = false;
  iconLike = ['fas', 'heart']; statusLike: boolean = false;
  iconDislike = ['fas', 'heart-broken']; statusDislike: boolean = false;

  combustibles_estacion = [];
  text:string = "";
  url:string = "";
  shareObj = {
      href: "",
      hashtag:""
  };
  public options:any;

  constructor(public config: Config, private toastr: ToastrService, public authService: AuthService, private estacionService: EstacionService, private socialAuthService: SocialService) {}

  ngOnInit() {

    this.configuraciones = this.config.data;
    this.longitud = parseFloat(this.estacion.longitud);
    this.latitud = parseFloat(this.estacion.latitud);
    (mapboxgl as any).accessToken = this.configuraciones.map.token;

    if(this.estacion.usuario_favorito) {
      this.statusFavorito = this.estacion.usuario_favorito.favorito;
  }
  if(this.estacion.usuario_likes) {
      this.statusLike = this.estacion.usuario_likes.like;
      this.statusDislike = !this.estacion.usuario_likes.like;
  }
//   let precio = ""; let unidad_cobro = "";
//   if(!this.solo_puntos_electricos && !this.solo_favoritos) {
//       this.estacion.combustibles.some(item => {
//           if(item.id == this.combustible.id) {
//               precio = item.precio.substr(0, item.precio.length - 2);
//               unidad_cobro = item.unidad_cobro;
//               return true;
//           }
//       });
//       this.url = this.configuraciones.compartir.url+this.estacion.id+"/"+this.combustible.id;
//       this.text = "Bencina en Línea - " + this.estacion.marca + " " + this.combustible.nombre_largo + " " + precio + " " + unidad_cobro ;
//       this.shareObj = {
//           href: this.configuraciones.compartir.url+this.estacion.id+"/"+this.combustible.id,
//           hashtag:""
//       };
//   }


  this.setOptionsParaGrafico();

  this.estacion.combustibles.forEach(comb => {
      let index_existe = 0; 
      if (this.combustibles_estacion.length > 0) {
          let existe_comb = this.combustibles_estacion.some((item, index) => {
              if (item.nombre_combustible == comb.nombre_largo) {
                  index_existe = index;
                  return true;
              }
          });
          if (existe_comb) {
              this.combustibles_estacion[index_existe].tipo_combustibles.push(comb)
          } else {
              this.combustibles_estacion.push({nombre_combustible: comb.nombre_largo, nombre_corto: this.validarPrimerCaracter(comb.nombre_corto), tipo_combustibles: Array(comb)});
          }
      } else {
          this.combustibles_estacion.push({nombre_combustible: comb.nombre_largo, nombre_corto: this.validarPrimerCaracter(comb.nombre_corto), tipo_combustibles: Array(comb)});
      }
  });


    this.modalDetalleEstacion = new window.bootstrap.Modal(
      document.getElementById('modalDetalleEstacion')
    );

    this.mapZoom = this.configuraciones.map.zoom;
    this.style = this.configuraciones.map.style;

    this.showModal();
  }

  ngAfterViewInit(): void {
    $('#modalDetalleEstacion').on('shown.bs.modal', () => {
      if (this.mapContainer) {
        this.initializeMap();
      }
    });

    $('#modalDetalleEstacion').on('hidden.bs.modal', () => {
        this.modalClose.emit();
      });

    Highcharts.chart('container-grafico-2', this.options);
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }

  initializeMap(): void {
    console.log("cargando mapa!!!");
    this.map = new mapboxgl.Map({
      container: this.mapContainer.nativeElement,
      style: this.style,
      zoom: this.mapZoom,
      center: [this.longitud, this.latitud]
    });

    let html = "";
    if(this.estacion.logo) {
        let el = document.createElement('div');
        let logobase64 = "";
        logobase64 = this.estacion.logo;

        html = '<div id="' + this.estacion.id + '"><img id="' + this.estacion.id + '" class="estacion-logo" src="' + logobase64 + '" alt=""></div>' ;
        el.innerHTML = html;
        el.className = 'marker ';
        el.id = this.estacion.id;

        new mapboxgl.Marker(el)
        .setLngLat([this.estacion.longitud, this.estacion.latitud])
        .addTo(this.map);
    }

    this.map.on('load', loadmap => {

    });
  }

  showModal() {
    this.modalDetalleEstacion.show();
  }

  closeModal() {
    this.modalDetalleEstacion.hide();
  }

  cambiarFavorito() {
    if(localStorage.getItem('api_token')) {   // Sólo si el usuario está logeado puede indicar favoritos
        this.estacionService.putFavorito(this.estacion.id, (this.statusFavorito ? 0 : 1))
            .subscribe(favorito => {
                if(favorito) {
                    if(favorito.mensaje) {
                        this.toastr.error('Ha ocurrido un error', '');
                    } else if(favorito) {                                
                        this.iconFavorito = (this.iconFavorito[0] == 'fas' ? ['fas', 'star'] : ['fas', 'star']);
                        this.statusFavorito  = !this.statusFavorito;
                        if(this.statusFavorito)
                            this.toastr.success('La Estación se ha marcado como favorita', '');
                        else
                            this.toastr.success('La Estación se ha desmarcado como favorita', '');
                    }
                }
            });
    }
}

cambiarLikeDislike(like: boolean) {
    if(localStorage.getItem('api_token')/*this.authService.isLoggedIn*/) {   // Sólo si el usuario está logeado puede indicar que le gusta o que no le gusta la estación
        if(like) {
            this.estacionService.putMeGusta(this.estacion.id, (this.statusLike ? 0 : 1))
                .subscribe(like => {
                    if(like) {
                        if(like.mensaje) {
                            this.toastr.error('Ha ocurrido un error', '');
                        } else if(like) {                                
                            this.iconLike = (this.iconLike[0] == 'fas' ? ['fas', 'heart'] : ['fas', 'heart']);
                            this.statusLike = !this.statusLike;    
                            this.iconDislike = ['fas', 'heart-broken'];
                            if(this.statusLike) {
                                this.estacion.likes+= 1; this.estacion.dislikes = (this.statusDislike ? this.estacion.dislikes - 1 : this.estacion.dislikes);
                                this.toastr.success('Te gusta la Estación', '');
                            }
                            else {
                                this.estacion.likes-= 1; this.estacion.likes = (this.statusDislike ? this.estacion.likes - 1 : this.estacion.likes);
                                this.toastr.success('Te dejó de gustar la Estación', '');
                            }
                            this.statusDislike = false;
                        }
                    }
                });
        } else {  
            this.estacionService.putNoMeGusta(this.estacion.id, (this.statusDislike ? 0 : 1))
                .subscribe(like => {
                    if(like) {
                        if(like.mensaje) {
                            this.toastr.error('Ha ocurrido un error', '');
                        } else if(like) {               
                            this.iconLike = ['fas', 'heart'];
                            this.statusDislike = !this.statusDislike;
                            this.iconDislike = ['fas', 'heart-broken'];
                            if(this.statusDislike) {
                                this.estacion.dislikes+= 1; this.estacion.likes = (this.statusLike ? this.estacion.likes - 1 : this.estacion.likes);
                                this.toastr.success('No te gusta la Estación', '');
                            }
                            else {
                                this.estacion.dislikes-= 1; this.estacion.dislikes = (this.statusLike ? this.estacion.dislikes - 1 : this.estacion.dislikes);
                                this.toastr.success('Te dejó de no gustar la Estación', '');  
                            }
                            this.statusLike  = false;
                        }
                    }
                });
        }
        this.marcarLikeDislikeEnListado.emit({id:this.estacion.id, like: like});
    }
}

public facebookSharing(shareObj: any){
    this.socialAuthService.facebookSharing(shareObj);
}

setOptionsParaGrafico() {
    let una_semana = (60*60*24*7*1000);  
    // Obtener última semana con datos desde la api y cantidad de semanas con datos desde la api
    let semana:any = new Date().getTime(); let cantidad_semanas:number = 0;
    this.estacion.ultimos_precios.forEach((item, index) => {
        if(new Date(item.semana).getTime() < semana) semana = new Date(item.semana).getTime();
        if(item.data.length > cantidad_semanas) cantidad_semanas = item.data.length;
    });
    // Para aquellos combustibles que tienen menos precios (menos semanas) forzar null para ubicar los precios dónde corresponda
    this.estacion.ultimos_precios.forEach((item, index) => {
        if(item.data.length < cantidad_semanas) {   // No hay datos para todas las semanas para este combustible
            let fecha = semana;
            if(new Date(item.semana).getTime() > semana) {  // Si la semana de precio del combustible es mayor a la semana en que tenemos datos
                let data = [];
                do {    // Avancemos de una semana en una para rellenar con null hasta la semana en que si hay precio para el combustible
                    data.push(null);
                    fecha += una_semana;
                } while (fecha < new Date(item.semana).getTime());
                item.data.forEach((itemD, indexD) => {
                    data.push(itemD);
                });                    
                item.data = data;
            }
        }
    });
    // calcular el día lunes de hace 12 semanas atrás
    let d = new Date(); // fecha actual
    let day = d.getDay();   // obtener el día de la fecha actual
    let diff = d.getDate() - day + (day == 0 ? -6:1);   // obtener el día lunes
    let lunes = new Date(d.setDate(diff));  // setear el día lunes
    d.setTime(lunes.getTime() - (una_semana*(cantidad_semanas-1)));    // setear a la variable el lunes de hace 12 semanas

    this.options = {
        chart: {
            height: 300
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function() {
                return 'Semana ' + Highcharts.dateFormat('%d-%m-%Y', this.x) + ': ' + this.y + ' '+ this.series.userOptions.unidad_cobro;
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function() {
                    return Highcharts.dateFormat('%d-%m-%Y', this.value);
                }
            },
            tickInterval: una_semana
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                pointInterval: una_semana,
                pointStart: Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
            }
        },
        series: this.estacion.ultimos_precios,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 300
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    }
}

validarPrimerCaracter(texto: string): string {
    if (texto.charAt(0) === 'A') {
        const nuevoTexto = texto.slice(1);
        return nuevoTexto;
    } else {
        return texto;
    }
}


}
