import { NgModule }      from '@angular/core';
import { FormatearPrecioPipe }          from './formatear-precio.pipe';

@NgModule({
    imports:        [ ],
    declarations:   [ FormatearPrecioPipe ],
    exports:        [ FormatearPrecioPipe ],
})

export class FormatearPrecioPipeModule {

    static forRoot() {
        return {
            ngModule: FormatearPrecioPipeModule,
            providers: [],
        };
    }
} 