import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EstacionService {
    private apiUrl:string = '';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    constructor(public config: Config, private http: HttpClient) { 
        this.apiUrl = config.data.api.url+"estacion";
    }

    getEstacion(id): Observable<any> {
        const url = `${this.apiUrl}_ciudadano/${id}`;
        let httpOptions;
        if(localStorage.getItem('api_token') && localStorage.getItem('api_token') != '') {
            httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                'Authorization': localStorage.getItem('tipo_token') + ' ' + localStorage.getItem('api_token')
                })
            };
        } else {
            httpOptions = this.httpOptions;
        }
        return this.http.get(url, httpOptions)
                   .pipe(tap((estacion) => console.log('getEstacion')),
                         catchError(this.handleError('getEstacion'))
                   );
    }

    getEstaciones(combustible, marcas, servicios): Observable<any> {
        const url = `${this.config.data.api.url}busqueda_estacion_filtro`;
        let httpOptions;
        if(localStorage.getItem('api_token') && localStorage.getItem('api_token') != '') {
            httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
            };
        } else {
            httpOptions = this.httpOptions;
        }
        return this.http.get(url, httpOptions)
                   .pipe(tap((estaciones) => console.log('getEstaciones')),
                         catchError(this.handleError('getEstaciones'))
                   );
    }

    putMeGusta(estacion_id, like): Observable<any> {
        const url = `${this.apiUrl}/${estacion_id}/me_gusta/${like}`;
        const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
            };
        return this.http.put(url, like, httpOptions)
                   .pipe(tap((usuario) => console.log('OK putMeGusta')),
                         catchError(this.handleError('putMeGusta'))
                   );
    }

    putNoMeGusta(estacion_id, like): Observable<any> {
        const url = `${this.apiUrl}/${estacion_id}/no_me_gusta/${like}`;
        const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
            };
        return this.http.put(url, like, httpOptions)
                   .pipe(tap((usuario) => console.log('OK putNoMeGusta')),
                         catchError(this.handleError('putNoMeGusta'))
                   );
    }

    putFavorito(estacion_id, favorito): Observable<any> {
        const url = `${this.apiUrl}/${estacion_id}/favorita/${favorito}`;
        const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
            };
        return this.http.put(url, favorito, httpOptions)
                   .pipe(tap((usuario) => console.log('OK putFavorito')),
                         catchError(this.handleError('putFavorito'))
                   );
    }

    getEstacionesFavoritas(): Observable<any> {
        const url = `${this.apiUrl}_favoritas`;
        let httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.get(url, httpOptions)
                   .pipe(tap((estaciones) => console.log('getEstacionesFavoritas')),
                         catchError(this.handleError('getEstacionesFavoritas'))
                   );
    }

    getElectrolineras(): Observable<any> {
        const url = `${this.config.data.api.url}electrolinera`;
        let httpOptions;
        if(localStorage.getItem('api_token') && localStorage.getItem('api_token') != '') {
            httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
            };
        } else {
            httpOptions = this.httpOptions;
        }
        return this.http.get(url, httpOptions)
                   .pipe(tap((estaciones) => console.log('getElectrolineras')),
                         catchError(this.handleError('getElectrolineras'))
                   );
    }

    getReporteComunal(combustible_id, region_id): Observable<any> {
        const url = `${this.apiUrl}es/precios_combustibles/${combustible_id}/reporte_comunal`;
        let httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          params:  new HttpParams().set('cod_region[]', region_id)
            };
        return this.http.get(url, httpOptions)
                   .pipe(tap((estaciones) => console.log('getReporteComunal')),
                         catchError(this.handleError('getReporteComunal'))
                   );
    }

    getReporteRegional(combustible_id, region_id): Observable<any> {
        const url = `${this.apiUrl}es/precios_combustibles/${combustible_id}/reporte_regional`;
        let httpOptions;
        if(region_id.length > 0) {
          let httpParams = new HttpParams();
          region_id.forEach((item, index) => {
            httpParams = httpParams.append('cod_region['+index+']', item);
          }); 
          httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params:  httpParams
              };
        } else {
          httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
              };
        }
        return this.http.get(url, httpOptions)
                   .pipe(tap((estaciones) => console.log('getReporteRegional')),
                         catchError(this.handleError('getReporteRegional'))
                   );
    }

    getReporteNacional(): Observable<any> {
        const url = `${this.apiUrl}es/precios_combustibles/reporte_zonal`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((estaciones) => console.log('getReporteNacional')),
                         catchError(this.handleError('getReporteNacional'))
                   );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
    }
}
