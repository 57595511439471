import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from "../../app.config";
import { CombustibleService } from '../../services/combustible.service';
import { EstacionService } from '../../services/estacion.service';
import { ZonaService } from '../../services/zona.service';
import { ComunaService } from '../../services/comuna.service';
import { ToastrService } from 'ngx-toastr';
import { SocialService } from "ngx-social-button";

@Component({
  selector: 'app-comunal',
  templateUrl: './comunal.component.html',
  styleUrls: ['./comunal.component.css']
})
export class ComunalComponent implements OnInit {
    configuraciones;
    loading:boolean = false;
    parametros = {'combustible_id': "", 'zona_id': "", 'region_id': "", 'orden': ""};
    zonas = [];
    combustibles = []; combustible:any = {};
    estaciones = []; regiones = []; comunas = []; comunasSeleccionadas = [];
    zona_id_seleccionada:number = 0;
    fecha_hora_actual:any;
    rowsOnPage:number = 10;
    sortBy:string = "combustible_precio";
    sortOrder:string = "asc"; ordenesPermitidos = ['asc', 'desc'];
    state: any;
    filteredData;

    /* Compartir */
    text:string = "";
    url:string = "";
    shareObj = {
        href: "",
        hashtag:""
    };

    url_descarga_pdf:string = "";
    url_descarga_excel:string = "";
    
    constructor(public config: Config, public combustibleService: CombustibleService, private toastr: ToastrService, public estacionService: EstacionService, public zonaService: ZonaService, public comunaService: ComunaService, private route: ActivatedRoute, private socialAuthService: SocialService) { }

    ngOnInit() {
        this.loading = true;
        this.configuraciones = this.config.data;
        this.fecha_hora_actual = new Date();

        this.cerrarMenu();

        this.rowsOnPage = this.configuraciones.general.lista_reportes;
        this.url_descarga_pdf = this.configuraciones.api.reportes.base+this.configuraciones.api.reportes.comunal.pdf;
        this.url_descarga_excel = this.configuraciones.api.reportes.base+this.configuraciones.api.reportes.comunal.excel;

        if(this.route.snapshot.paramMap.get('combustible_id')) {
            this.parametros['combustible_id'] = this.route.snapshot.paramMap.get('combustible_id');
        }

        if(this.route.snapshot.paramMap.get('zona_id')) {
            this.parametros['zona_id'] = this.route.snapshot.paramMap.get('zona_id');
        }

        if(this.route.snapshot.paramMap.get('region_id')) {
            this.parametros['region_id'] = this.route.snapshot.paramMap.get('region_id');
        }

        if(this.route.snapshot.paramMap.get('orden')) {
            this.parametros['orden'] = this.route.snapshot.paramMap.get('orden');
            if(this.ordenesPermitidos.indexOf(this.parametros['orden']) != -1) this.sortOrder = this.parametros['orden'];
            else this.parametros['orden'] = "";
        }

        this.url = this.configuraciones.compartir.url_reporte_comunal;
        this.text = "Bencina en Línea - Reporte Comunal";
        this.shareObj = {
            href: this.configuraciones.compartir.url_reporte_comunal,
            hashtag:""
        };

        // Obtener todos los combustibles para las pestañas
        this.combustibleService.getCombustibles()
            .subscribe(combustibles => {
                if(combustibles) {
                    if(combustibles.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
                        this.loading = false;
                    } else if(combustibles) {
                        //this.combustibles = combustibles.data;

                        combustibles.data.forEach(combustible => {
                            let existe = false;
                            existe = this.combustibles.some(comb => {
                              if(comb.nombre_largo === combustible.nombre_largo) {
                                  comb.grupo = comb.grupo + "," + combustible.id.toString();
                                  return true;
                              }
                            });
                            if (!existe) {
                              combustible.grupo = combustible.id.toString();
                              this.combustibles.push(combustible)
                            }
                          });

                        if(this.combustibles.length > 0) {
                            if(this.parametros['combustible_id'] != "") {
                                this.combustibles.some(combustible => {
                                    if(combustible.grupo == this.parametros['combustible_id']) {
                                        this.combustible = combustible;
                                        return true;
                                    }
                                });
                            } else {
                                this.combustible = this.combustibles[0];
                            }
                            this.url = this.configuraciones.compartir.url_reporte_comunal+this.combustible["id"];
                        } else {
                            this.state = [{state: "error", origen: "reporte_comunal"}];
                        }
                        this.loading = false;
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
                    this.loading = false;
                }
            });

        this.loading = true;
        // Obtener todas las zonas geográficas para el acordeon
        this.zonaService.getZonas()
            .subscribe(zonas => {
                if(zonas) {
                    if(zonas.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener las zonas geográficas.', '');
                        this.zonas = [];
                    } else if(zonas) {
                        this.zonas = zonas.data;
                        if(this.parametros['zona_id'] != "") {
                            this.zona_id_seleccionada = parseInt(this.parametros['zona_id']);
                            this.url = this.configuraciones.compartir.url_reporte_comunal+this.combustible["id"]+"/"+this.zona_id_seleccionada;
                            this.obtenerRegiones();
                        }
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener las zonas geográficas.', '');
                    this.loading = false;
                }
            });
    }

    clickCombustible(combustible_index) {
        this.combustible = this.combustibles[combustible_index];
        
        this.url = this.configuraciones.compartir.url_reporte_comunal+this.combustible["grupo"];
        this.fecha_hora_actual = new Date();
        this.regiones = [];
        this.estaciones = [];

        $(".btn.btn-link.w-100").addClass("collapsed");
        $(".collapse.show").removeClass("show");
    }

    clickZona(zona_id) {
        this.loading = true;
        this.fecha_hora_actual = new Date();
        this.zona_id_seleccionada = zona_id;
        this.url = this.configuraciones.compartir.url_reporte_comunal+this.combustible["grupo"]+"/"+this.zona_id_seleccionada;

        this.obtenerRegiones();
    }
    clickRegion(region_id) {
        this.loading = true;
        this.fecha_hora_actual = new Date();
        this.url = this.configuraciones.compartir.url_reporte_comunal+this.combustible["grupo"]+"/"+this.zona_id_seleccionada+"/"+region_id+"/"+this.sortOrder;
        this.obtenerReporte(region_id);
    }

    obtenerRegiones() {        
        this.comunaService.getRegiones()
            .subscribe(regiones => {
                if(regiones) {
                    this.regiones = regiones.data;
                    if(this.parametros['region_id'] != "") {
                        this.obtenerReporte(this.parametros['region_id']);
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.state = [{state: "error", origen: "reporte_comunal"}];
                    this.loading = false;
                }
            });
    }

    obtenerReporte(region_id) {
        // Obtener todas las estaciones para el reporte
        this.estacionService.getReporteComunal(this.combustible["grupo"], [region_id])
            .subscribe(estaciones => {
                if(estaciones) {
                    if(estaciones.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener el reporte.', '');
                        this.estaciones = [];
                    } else if(estaciones) {
                        this.estaciones = estaciones.data;
                        this.filteredData = estaciones.data;
                    }
                    this.loading = false;
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener el reporte.', '');
                    this.loading = false;
                }

                this.comunas = this.getListadoComunas(this.estaciones);
                this.comunasSeleccionadas = [];
            });
    }

    facebookSharing(){
        this.shareObj['url'] = this.url;
        this.socialAuthService.facebookSharing(this.shareObj);
    }

    cerrarMenu() {
        let element = document.getElementById("veloFull");
        element.classList.remove("show");
        element = document.getElementById("veloFullNav");
        element.classList.remove("show");
    }

    updateData(query:string) {
        const lowercaseQuery = query.toLowerCase();

        if(lowercaseQuery) {
            this.filteredData = this.estaciones.filter(a => 
            a.comuna_nombre.toLowerCase().indexOf(lowercaseQuery) >= 0 ||
            a.estacion_direccion.toLowerCase().indexOf(lowercaseQuery) >= 0 ||
            a.marca_nombre.toLowerCase().indexOf(lowercaseQuery) >= 0);
        } else {
            this.filteredData = this.estaciones;
        }
    }

    getListadoComunas(arr: any[]): any[] {
        return arr.reduce((unique, item) => {
            if (!unique.find((obj : any) => obj['id'] === item['comuna_id'])) {
                unique.push({id: item['comuna_id'], nombre: item['comuna_nombre']});
            }
            return unique;
        }, []);
    }

    seleccionarTodasLasComunas(sel) {
        if(sel) {
            this.comunasSeleccionadas = this.comunas;
        } else {
            this.comunasSeleccionadas = [];
        }
    }

    onSelectionChange(selectedItems: any) {
        if(this.comunasSeleccionadas.length > 0) {
            this.filteredData = this.estaciones.filter(a => 

                this.comunasSeleccionadas.some(com => {
                    if(com.nombre === a.comuna_nombre) {
                        return true;
                    }
                })
            );
        } else {
            this.filteredData = this.estaciones;
        }
    }
}
