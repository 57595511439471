import { Component, OnInit, Inject } from '@angular/core';
import glosario from "./glosario.json";
 
@Component({
  selector: 'app-glosario',
  templateUrl: './glosario.component.html',
  styleUrls: ['./glosario.component.css']
})
export class GlosarioComponent implements OnInit {

datos: any = glosario;
// [
//   {
//     "PATH"  : "/Reporte_Nacional",
//     "NOMBRE": "Reporte Nacional",
//     "SIGNIFICADO": "Este reporte sirve para mostrar Nacional",
   
//   },
//   {
//     "PATH"  : "/Reporte_Regional",
//     "NOMBRE": "Reporte Regional",
//     "SIGNIFICADO": "Este reporte sirve para mostrar Regional",
//   },   
//   {
//     "PATH"  : "/Reporte_Comunal",
//     "NOMBRE": "Reporte Comunal",
//     "SIGNIFICADO": "Este reporte sirve para mostrar Comunal"
//   },
//   {
//     "PATH"  : "/Precios_de_combustibles_actualizados",
//     "NOMBRE": "Precios de combustibles actualizados",
//     "SIGNIFICADO": "AAA"
//   },
//   {
//     "PATH"  : "/Datos_de_la_Estacion_de_Servicio",
//     "NOMBRE": "Datos de la Estacion de Servicio",
//     "SIGNIFICADO": "BBB"
//   },
//   {
//     "PATH"  : "/Datos_de_la_Electrolinera",
//     "NOMBRE": "Datos de la Electrolinera",
//     "SIGNIFICADO": "CCC"
//   },
//   {
//     "PATH"  : "/Resultado_de_Control_de_Calidad",
//     "NOMBRE": "Resultado de Control de Calidad",
//     "SIGNIFICADO": "DDD"
//   }
// ]
  route: any;

  constructor() { }

  ngOnInit() {

  }

}
