import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.css']
})
export class StatesComponent implements OnInit {

    @Output() cerrar_state: EventEmitter<any> = new EventEmitter();

    logo: string = "ok";
    titulo: string = "";
    lead: any;
    botones: any;

    @Input() set state(data) {
        if(data) {
            if(data[0]['state'] == "ok") {
                this.logo = "ok";
                if(data[0]['origen'] == 'registro') {
                    this.translate.get('REGISTRO.STATES.OK.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('REGISTRO.STATES.OK.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });
                    
                    this.translate.get('REGISTRO.STATES.OK.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "/login", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'recuperar') {
                    this.translate.get('RECUPERAR.STATES.OK.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('RECUPERAR.STATES.OK.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "/login", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'clave_unica') {
                    this.translate.get('CLAVE_UNICA.STATES.OK.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('CLAVE_UNICA.STATES.OK.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "/mis_datos", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'mis_datos') {
                    this.translate.get('MIS_DATOS.STATES.OK.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('MIS_DATOS.STATES.OK.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "", "estilo": "primary"}];
                    });
                }
            }
            if(data[0]['state'] == "error") {
                this.logo = "error";
                if(data[0]['origen'] == 'not_found') {
                    this.translate.get('NOT_FOUND.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('NOT_FOUND.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });

                    this.translate.get('NOT_FOUND.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "/", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'registro') {
                    this.translate.get('REGISTRO.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    if(data[0]['data']) {
                        this.lead = [{"texto": data[0]['data'][0], "tipo": ""}];
                    } else {
                        this.translate.get('REGISTRO.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                            this.lead = [{"texto": res, "tipo": ""}];
                        });
                    }

                    this.translate.get('REGISTRO.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'registro_validar') {
                    this.translate.get('REGISTRO.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('REGISTRO.STATES.ERROR.SUBTITULO_2').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });

                    this.translate.get('REGISTRO.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'login') {
                    this.translate.get('LOGIN.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('LOGIN.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });
                    if(data[0]['data']) {
                        this.lead.push({"texto": data[0]['data'][0], "tipo": ""});
                    }

                    this.translate.get('LOGIN.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'mis_datos') {
                    this.translate.get('MIS_DATOS.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('MIS_DATOS.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });
                    if(data[0]['data']) {
                        this.lead.push({"texto": data[0]['data'][0], "tipo": ""});
                    }

                    this.translate.get('MIS_DATOS.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "/login", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'clave_unica') {
                    this.translate.get('CLAVE_UNICA.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('CLAVE_UNICA.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });
                    if(data[0]['data']) {
                        this.lead.push({"texto": data[0]['data'][0], "tipo": ""});
                    }

                    this.translate.get('CLAVE_UNICA.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "/login", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'recuperar') {
                    this.translate.get('RECUPERAR.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('RECUPERAR.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });
                    if(data[0]['data']) {
                        this.lead.push({"texto": data[0]['data'][0], "tipo": ""});
                    }

                    this.translate.get('RECUPERAR.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "", "estilo": "primary"}];
                    });
                }
                if(data[0]['origen'] == 'busqueda_estaciones') {
                    this.translate.get('ESTACION.STATES.ERROR.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });

                    this.translate.get('ESTACION.STATES.ERROR.SUBTITULO').subscribe((res: string) => {
                        this.lead = [{"texto": res, "tipo": ""}];
                    });
                    if(data[0]['data']) {
                        this.lead.push({"texto": data[0]['data'][0], "tipo": ""});
                    }

                    this.translate.get('ESTACION.STATES.ERROR.BOTON').subscribe((res: string) => {
                        this.botones = [{"texto": res, "url": "", "estilo": "primary"}];
                    });   
                }
            }
            if(data[0]['state'] == "empty") {
                this.logo = "ban";
                if(data[0]['origen'] == 'busqueda_estaciones') {
                    this.translate.get('ESTACION.STATES.EMPTY.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });
                }
                if(data[0]['origen'] == 'estaciones_favoritas') {
                    this.translate.get('FAVORITAS.STATES.EMPTY.TITULO').subscribe((res: string) => {
                        this.titulo = res;
                    });
                }
            }
        }
    }

    constructor(public translate: TranslateService, public router: Router) {
        translate.addLangs(['en', 'es']);
        translate.setDefaultLang('es');
    }

    ngOnInit() { }

    onAccion(url) {
        if(url != '') {
            this.router.navigateByUrl(url);
        } else {
            this.cerrar_state.emit();
        }
    }

}
