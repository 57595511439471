import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Config } from "../../app.config";
import { CombustibleService } from '../../services/combustible.service';
import { EstacionService } from '../../services/estacion.service';
import { ZonaService } from '../../services/zona.service';
import { ComunaService } from '../../services/comuna.service';
import { ToastrService } from 'ngx-toastr';
import { SocialService } from "ngx-social-button";
import { DataTableDirective } from 'angular-datatables';
import { reduce } from 'highcharts';

@Component({
  selector: 'app-regional',
  templateUrl: './regional.component.html',
  styleUrls: ['./regional.component.css']
})
export class RegionalComponent implements AfterViewInit, OnDestroy, OnInit {
    configuraciones;
    loading:boolean = false;
    parametros = {'combustible_id': "", 'zona_id': "", 'region_id': "", 'orden': []};
    busquedaForm: FormGroup;
    zonas = []; combustibles = []; combustible = {unidad_cobro_corto: ""};
    estaciones = []; regiones = []; regionesSeleccionadas = [];
    fecha_hora_actual:any;
    ordenesPermitidos = ['asc', 'desc']; orden = [];
    state: any;

    /* Compartir */
    text:string = "";
    url:string = "";
    shareObj = {
        href: "",
        hashtag:""
    };

    dtOptions: DataTables.Settings = {};
    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<any> = new Subject();
    groupColumn = 0;

    url_descarga_pdf:string = "";
    url_descarga_excel:string = "";

    @ViewChild(DataTableDirective, {static: false})
    datatableElement: DataTableDirective;

    constructor(public config: Config, public combustibleService: CombustibleService, private toastr: ToastrService, public estacionService: EstacionService,
        public zonaService: ZonaService, public comunaService: ComunaService, private route: ActivatedRoute, private socialAuthService: SocialService,
        private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.loading = true;
        this.configuraciones = this.config.data;
        this.fecha_hora_actual = new Date();

        this.cerrarMenu();

        this.url = this.configuraciones.compartir.url_reporte_regional;

        if(this.route.snapshot.paramMap.get('combustible_id')) {
            this.parametros['combustible_id'] = this.route.snapshot.paramMap.get('combustible_id');
            this.url = this.configuraciones.compartir.url_reporte_regional+this.parametros['combustible_id'];
            this.url_descarga_pdf = this.configuraciones.api.reportes.base+this.parametros['combustible_id']+'/'+this.configuraciones.api.reportes.regional.pdf;
            this.url_descarga_excel = this.configuraciones.api.reportes.base+this.parametros['combustible_id']+'/'+this.configuraciones.api.reportes.regional.excel;
        }

        if(this.route.snapshot.paramMap.get('region_id')) {
            this.parametros['region_id'] = this.route.snapshot.paramMap.get('region_id');
            // Generar arreglo con las regiones que vienen en el parámetros para setear filtro y luego buscar
            let parametros_cod_region_arreglo = this.parametros['region_id'].split(',');
            let cod_regiones_str = "";
            parametros_cod_region_arreglo.forEach((item, index) => {
                this.regionesSeleccionadas.push({cod_region:item, nom_region: ""});
                cod_regiones_str = (index==0 ? "?cod_region["+index+"]="+item : cod_regiones_str+"&cod_region["+index+"]="+item);
            });
            this.url = this.configuraciones.compartir.url_reporte_regional+this.parametros['combustible_id']+"/"+cod_regiones_str;
            this.url_descarga_pdf = this.configuraciones.api.reportes.base+this.parametros['combustible_id']+'/'+this.configuraciones.api.reportes.regional.pdf+'/'+cod_regiones_str;
            this.url_descarga_excel = this.configuraciones.api.reportes.base+this.parametros['combustible_id']+'/'+this.configuraciones.api.reportes.regional.excel+'/'+cod_regiones_str;
        }

        if(this.route.snapshot.paramMap.get('orden')) {
            this.parametros['orden'] = this.route.snapshot.paramMap.get('orden').split(',');
            this.url = this.configuraciones.compartir.url_reporte_regional+this.parametros['combustible_id']+"/"+this.route.snapshot.paramMap.get('region_id')+"/"+this.parametros['orden'];
        }

        this.text = "Bencina en Línea - Reporte Regional";
        this.shareObj = {
            href: this.url,
            hashtag:""
        };

        this.busquedaForm = this.formBuilder.group({
            combustible: ["", Validators.required]
        });

        // Obtener todas las zonas geográficas para el acordeon
        this.zonaService.getZonas()
            .subscribe(zonas => {
                if(zonas) {
                    if(zonas.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener las zonas geográficas.', '');
                        this.zonas = [];
                    } else if(zonas) {
                        this.zonas = zonas.data;
                        this.loading = false;
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener las zonas geográficas.', '');
                    this.loading = false;
                }
            });

        // Obtener todos los combustibles para las pestañas
        this.combustibleService.getCombustibles()
            .subscribe(combustibles => {
                if(combustibles) {
                    if(combustibles.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
                        this.loading = false;
                    } else if(combustibles) {
                        //this.combustibles = combustibles.data;

                        // const agrupados = reduce(combustibles.data, (agrup, combustible) => {
                        //   const {nombre_largo, id} = combustible;
                        //   if (!agrup[nombre_largo]) {
                        //     agrup[nombre_largo] = { nombre_largo, ids: [] };
                        //   }
                        //   agrup[nombre_largo].ids.push(id);
                        //   return agrup;
                        // },{});

                        combustibles.data.forEach(combustible => {
                          let existe = false;
                          existe = this.combustibles.some(comb => {
                            if(comb.nombre_largo === combustible.nombre_largo) {
                                comb.grupo = comb.grupo + "," + combustible.id.toString();
                                return true;
                            }
                          });
                          if (!existe) {
                            combustible.grupo = combustible.id.toString();
                            this.combustibles.push(combustible)
                          }
                        });


                        if(this.combustibles.length > 0) {
                            if(this.parametros['combustible_id'] != "") {
                                this.combustibles.some(combustible => {
                                    if(combustible.grupo == this.parametros['combustible_id']) {
                                        this.combustible = combustible;
                                        this.busquedaForm.patchValue({combustible: this.combustible['grupo']})
                                        return true;
                                    }
                                });
                            }
                        } else {
                            this.state = [{state: "error", origen: "reporte_regional"}];
                        }
                        this.loading = false;
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
                    this.loading = false;
                }
            });

        this.loading = true;
        this.comunaService.getRegiones()
            .subscribe(regiones => {
                if(regiones) {
                    this.regiones = regiones.data;
                    if(this.parametros['region_id'] != "") {
                        // Si vienen las regiones cómo parametros juntp con el combustible realizar la búsqueda y mostrar reporte
                        let cod_regiones = [];
                        this.regionesSeleccionadas.forEach((item, index) => {
                            cod_regiones.push(item.cod_region);
                            this.regiones.some(region => {
                                if(region.cod_region == item.cod_region) {
                                    item.nom_region = region.nom_region;
                                    return true;
                                }
                            });
                        });
                        this.obtenerReporte(this.parametros['combustible_id'], cod_regiones);
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.state = [{state: "error", origen: "reporte_regional"}];
                    this.loading = false;
                }
            });

        this.obtenerOrdenDeColumnas();
        this.dtOptions = {
            paging: false,  // Quitar paginación, mostrar todo
            searching: false,   // Quitar campo para buscar
            order: [[ 0, 'asc' ], [ 1, 'asc' ]],
            columnDefs: [ { orderable: false, targets: [2,6] }, {width: "17%", targets: [6]}, {width: "20%", targets: [2]} ],   // Deshabilitar orden para la coluna región y la columna de acciones (última)
            responsive: true,   // Use this attribute to enable the responsive extension
            info: false,
            language: {
                zeroRecords: "Sin datos",
                emptyTable: "Sin datos"
            },
            drawCallback: () => {
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    const self = this;
                    var last=null;
                    var rows = dtInstance.rows({page:'current'}).nodes();
                    let zonas = this.zonas;
                    dtInstance.column(this.groupColumn, {page:'current'}).data().each(function (group, i) {
                        if (last !== group) {
                            let nombre_grupo_zona = "";
                            zonas.some(item => {
                                if(item.id == group) {
                                    nombre_grupo_zona = item.nombre;
                                    return true;
                                }
                            });
                            $(rows).eq(i).before('<tr class="group"><td colspan="5"><h3>'+nombre_grupo_zona+'</h3></td></tr>');
                            $("tr.group").unbind('click');
                            $("tr.group").bind('click', () => {
                                self.ordenarPorGrupo();
                            });
                            last = group;
                        }
                    });
                });
            }
        };

    }
    ngAfterViewInit(): void { this.dtTrigger.next(); }

    ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
     }

    onSubmitBusqueda(busquedaForm) {
        this.loading = true;
        this.fecha_hora_actual = new Date();
        let orden = "";
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            orden = dtInstance.order().toString();
            let cod_regiones = []; let cod_regiones_str:string = "";
            this.regionesSeleccionadas.forEach((item, index) => {
                cod_regiones.push(item.cod_region);
                cod_regiones_str = (index==0 ? "?cod_region["+index+"]="+item.cod_region : cod_regiones_str+"&cod_region["+index+"]="+item.cod_region);
            });
            this.url = this.configuraciones.compartir.url_reporte_regional+busquedaForm.combustible+"/"+cod_regiones.toString()+"/"+orden;
            this.url_descarga_pdf = this.configuraciones.api.reportes.base+busquedaForm.combustible+'/'+this.configuraciones.api.reportes.regional.pdf+'/'+cod_regiones_str;
            this.url_descarga_excel = this.configuraciones.api.reportes.base+busquedaForm.combustible+'/'+this.configuraciones.api.reportes.regional.excel+'/'+cod_regiones_str;
            this.combustibles.some(combustible => {
                if(combustible.id == busquedaForm.combustible) {
                    this.combustible = combustible;
                    return true;
                }
            });
            this.obtenerReporte(busquedaForm.combustible, cod_regiones);
        });
    }

    obtenerReporte(combustible, region_id) {
        // Obtener todas las estaciones para el reporte
        this.estacionService.getReporteRegional(combustible, region_id)
            .subscribe(estaciones => {
                if(estaciones) {
                    if(estaciones.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener el reporte.', '');
                        this.estaciones = [];
                    } else if(estaciones) {
                        this.estaciones = estaciones.data.sort(e => e.orden);
                    }
                    // Calling the DT trigger to manually render the table
                    //this.dtTrigger.next();
                    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                      // Destroy the table first
                      dtInstance.destroy();
                      // Call the dtTrigger to rerender again
                      this.dtTrigger.next();
                    });
                    this.loading = false;
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener el reporte.', '');
                    this.loading = false;
                }
            });
    }

    seleccionarTodasLasRegiones(sel) {
        if(sel) {
            this.regionesSeleccionadas = this.regiones;
        } else {
            this.regionesSeleccionadas = [];
        }
    }

    facebookSharing(shareObj: any){
        this.shareObj['url'] = this.url;
        this.socialAuthService.facebookSharing(this.shareObj);
    }

    ordenarPorGrupo(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            let currentOrder = dtInstance.order()[0];
            if ( currentOrder && currentOrder.length > 0 && currentOrder[0] === 0 && currentOrder[1] === 'asc' ) {
                dtInstance.order( [ this.groupColumn, 'desc' ], [ 1, 'desc' ] ).draw();
            } else {
                dtInstance.order( [ this.groupColumn, 'asc' ], [ 1, 'asc' ] ).draw();
            }
        });
    }

    obtenerOrdenDeColumnas() {
        if(this.parametros['orden'].length > 0) {
            if(this.parametros['orden'].length%2 == 0) {    // Si el largo de los parámetros es impar significa que viene algo mal, por lo que no lo vamos a considerar => utilizar orden por defecto
                let i = 0; let index_anterior = 0;
                this.parametros['orden'].forEach((item, index) => {
                    i = index+1;
                    if(i%2 == 0) { // Si el índice del parámetro es par corresponde al orden
                        if(this.ordenesPermitidos.indexOf(item) != -1)  // Asegurarnos de que el orden es permitido (asc, desc), si no lo es asignar asc
                            this.orden[index_anterior] = [this.orden[index_anterior], item];
                        else
                            this.orden[index_anterior] = [this.orden[index_anterior], 'asc'];
                    } else { // Si el índice del parámetro no es par corresponde a la columna
                        if(Number.isInteger(parseInt(item)))  // Asegurarnos de que el orden corresponda a un numérico
                            this.orden.push(item);
                        else
                            this.orden.push(this.groupColumn);
                        index_anterior = this.orden.length - 1;
                    }
                });
            } else {
                this.orden = [[ this.groupColumn, 'asc' ], [ 1, 'asc' ]];
            }
        } else {
            this.orden = [[ this.groupColumn, 'asc' ], [ 1, 'asc' ]];
        }
    }

    cerrarMenu() {
        let element = document.getElementById("veloFull");
        element.classList.remove("show");
        element = document.getElementById("veloFullNav");
        element.classList.remove("show");
    }
}
