import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MarcaService {
    private apiUrl:string = '';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', "Cache-Control": "max-age=3000, must-revalidate" })
    };

    constructor(public config: Config, private http: HttpClient) { 
        this.apiUrl = config.data.api.url+"marca_ciudadano";
    }

    getMarcas(): Observable<any> {
        const url = `${this.apiUrl}`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((marcas) => console.log('getMarcas')),
                         catchError(this.handleError('getMarcas'))
                   );
    }

    getMarcaLogo(id): Observable<any> {
        const url = `${this.config.data.api.url}marca_logo/${id}`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((logo) => console.log('getMarcaLogo')),
                         catchError(this.handleError('getMarcaLogo'))
                   );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
    }
}
