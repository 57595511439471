import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    
    private apiUrl = '';  // URL to web api

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(public config: Config, private http: HttpClient) { 
        this.apiUrl = config.data.api.url+"ciudadano/";
    }

    postClaveUnica(data): Observable<any> {
        const url = `${this.apiUrl}clave_unica`;
        return this.http.post(url, data, this.httpOptions)
                   .pipe(tap((usuario) => console.log('postClaveUnica')),
                         catchError(this.handleError('postClaveUnica'))
                   );
    }


    postLogin(data): Observable<any> {
        const url = `${this.apiUrl}login`;
        return this.http.post(url, data, this.httpOptions)
                   .pipe(tap((usuario) => console.log('OK postLogin')),
                         catchError(this.handleError('postLogin'))
                   );
    }

    getUsuario(): Observable<any> {
        const url = `${this.apiUrl}perfil`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.get(url, httpOptions)
                   .pipe(tap((usuario) => console.log('getUsuario')),
                         catchError(this.handleError('getUsuario'))
                   );
    }

    postRegistro(data): Observable<any> {

        const url = `${this.apiUrl}registro`;
        return this.http.post(url, data, this.httpOptions)
                   .pipe(tap((nuevoUsuario) => console.log(`OK postRegistro`)),
                         catchError(this.handleError('postRegistro'))
                   );
    }

    postCompletarRegistro(data): Observable<any> {
        const url = `${this.apiUrl}completar_registro`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((nuevoUsuario) => console.log(`OK postCompletarRegistro`)),
                         catchError(this.handleError('postCompletarRegistro'))
                   );
    }

    postModificar(data): Observable<any> {
        const url = `${this.apiUrl}modificar`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((usuario) => console.log('OK postModificar')),
                         catchError(this.handleError('postModificar'))
                   );
    }

    postModificarComuna(data): Observable<any> {
        const url = `${this.apiUrl}modificar_comuna`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((usuario) => console.log('OK postModificarComuna')),
                         catchError(this.handleError('postModificarComuna'))
                   );
    }

    postModificarContrasena(data): Observable<any> {
        const url = `${this.apiUrl}modificar_contrasena`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((usuario) => console.log('OK postModificarContrasena')),
                         catchError(this.handleError('postModificarContrasena'))
                   );
    }

    postModificarContrasenaCuenta(data): Observable<any> {
        const url = `${this.apiUrl}modificar_contrasena_cuenta`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((usuario) => console.log('OK postModificarContrasenaCuenta')),
                         catchError(this.handleError('postModificarContrasenaCuenta'))
                   );
    }

    postRegistroClaveUnica(data): Observable<any> {       
        const url = `${this.apiUrl}registro_clave_unica`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((nuevoUsuario) => console.log(`OK postRegistroClaveUnica`)),
                         catchError(this.handleError('postRegistroClaveUnica'))
                   );
    }

    postVerificarCorreo(data): Observable<any> {
        const url = `${this.config.data.api.url}validar_correo`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((verificadoUsuario) => console.log(`OK postVerificarCorreo`)),
                         catchError(this.handleError('postVerificarCorreo'))
                   );
    }

    postRecuperarContrasena(data): Observable<any> {
        const url = `${this.apiUrl}recuperar_contrasena`;
        return this.http.post(url, data, this.httpOptions)
                   .pipe(tap((data) => console.log(`OK postRecuperarContrasena`)),
                         catchError(this.handleError('postRecuperarContrasena'))
                   );
    }

    postRecuperarModificarContrasena(data): Observable<any> {
        const url = `${this.apiUrl}modificar_contrasena`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, data, httpOptions)
                   .pipe(tap((usuario) => console.log('OK postRecuperarModificarContrasena')),
                         catchError(this.handleError('postRecuperarModificarContrasena'))
                   );
    }

    postLogout(): Observable<any> {
        const url = `${this.apiUrl}logout`;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
        return this.http.post(url, [], httpOptions)
                   .pipe(tap((data) => console.log('OK postLogout')),
                         catchError(this.handleError('postLogout'))
                   );
    }

    generarState(largo) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for( var i = 0; i < largo; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
  }
}
