import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-redireccionar',
  templateUrl: './redireccionar.component.html',
  styleUrls: ['./redireccionar.component.css']
})
export class RedireccionarComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();
    state: any = false;
    loading:boolean = false;

    constructor(private route: ActivatedRoute, public router: Router, public authService: AuthService, public usuarioService: UsuarioService) { }

    ngOnInit() {
        if(this.route.snapshot.queryParams['code']) {
            let code = this.route.snapshot.queryParams['code'];
            let state = this.route.snapshot.queryParams['code'];
            // Llamar a la api con el code para obtener datos del usuario
            this.loading = true;
            this.usuarioService.postClaveUnica({code: code, state: state})
                .subscribe(usuario => {
                    if(usuario) {
                        if(usuario.mensaje) {
                            this.loading = false;
                            this.state = [{state: "error", origen: "clave_unica", data: [usuario.mensaje]}];    
                        } else if(usuario.data) {
                            this.loading = false;
                            localStorage.setItem('tipo_token', usuario.data.tipo_token);
                            localStorage.setItem('api_token', usuario.data.api_token);
                            this.obtenerDatosUsuario(usuario.data);
                        }
                    } else {
                            this.loading = false;
                        this.state = [{state: "error", origen: "clave_unica"}];
                    }
                });
        } else {
            // si no viene el code es por que ha ocurrido un error o algo no anda bien... redireccionar al login
            this.router.navigateByUrl("/login");
        }
    }

    private obtenerDatosUsuario(data) {
        this.loading = true;
        this.usuarioService.getUsuario()
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.state = [{state: "error", origen: "clave_unica", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        if(usuario.data.correo_verificado) {    // Si el correo está verificado redirigir a mis datos
                            this.authService.login();
                            this.router.navigateByUrl("/mis_datos");
                        } else {                                // Si el correo no está verificado el usuario debe completar el registro con clave única
                            this.router.navigateByUrl("/clave_unica_registro");
                        }
                    }
                } else {
                    this.loading = false;
                    this.state = [{state: "error", origen: "clave_unica"}];
                }
            });
    }

}