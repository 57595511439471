import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faGasPump, faChevronRight, faChargingStation, faFileAlt, faCheckCircle, faExclamationTriangle, faTimesCircle, faEdit, faStar,
    faSignOutAlt, faCrosshairs, faFilter, faArrowLeft, faSortNumericDown, faHeart, faHeartBroken, faAngleRight, faClock, faConciergeBell,
    faMoneyBillAlt, faAward, faShareAlt, faSortNumericUpAlt, faBolt, faMapMarkerAlt, faBan, faTools, faUserCheck, faDownload, faFilePdf,
    faFileExcel, faChartBar, faSearch, faInfoCircle, faPhoneSquareAlt, faExclamationCircle,faPlus, faUser, faLocationArrow, faWindowClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar, faHeart as farHeart/*, faHeartBroken as farHeartBroken*/ } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxSocialButtonModule, SocialServiceConfig } from "ngx-social-button";
import { MomentModule } from 'ngx-moment';
import 'moment/locale/es';
import { DataTableModule } from "angular2-datatable";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CabeceraComponent } from './cabecera/cabecera.component';
import { AppRoutingModule } from './app-routing.module';
import { RegistroComponent } from './registro/registro.component';
import { StatesComponent } from './states/states.component';

import { EqualTextValidatorDirective } from './custom-directives/equal-text-validator.directive';
import { OnlyNumber } from './custom-directives/onlynumber.directive';

import { registerLocaleData, DatePipe } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';
import { ClaveUnicaComponent } from './clave-unica/clave-unica.component';
import { MisDatosComponent } from './mis-datos/mis-datos.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BusquedaEstacionesComponent } from './busqueda-estaciones/busqueda-estaciones.component';
import { ListadoEstacionesComponent } from './listado-estaciones/listado-estaciones.component';
import { EstacionComponent } from './busqueda-estaciones/estacion/estacion.component';

import { Config } from "./app.config";
import { RedireccionarComponent } from './clave-unica/redireccionar/redireccionar.component';
import { BusquedaElectrolinerasComponent } from './busqueda-electrolineras/busqueda-electrolineras.component';
import { EstacionesFavoritasComponent } from './estaciones-favoritas/estaciones-favoritas.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { GlosarioComponent } from './glosario/glosario.component';

import { FormatearPrecioPipeModule } from "./pipes/formatear-precio-pipe.module";
import { ComunalComponent } from './reportes/comunal/comunal.component';
import { RegionalComponent } from './reportes/regional/regional.component';
import { ZonalComponent } from './reportes/zonal/zonal.component';
import { FooterComponent } from './footer/footer.component';
import { ReporteVariacionPreciosComponent } from './reporte-variacion-precios/reporte-variacion-precios.component';
import { ReporteVariacionPreciosDetalleComponent } from './reporte-variacion-precios-detalle/reporte-variacion-precios-detalle.component';
import { ModalDetalleEstacionComponent } from './modal-detalle-estacion/modal-detalle-estacion.component';

registerLocaleData(localeEsCL);

const appVersion = new Date();

// Configs
export function getAuthServiceConfigs() {
    let config = new SocialServiceConfig()
        .addFacebook("793162004451835");
    return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CabeceraComponent,
    RegistroComponent,
    StatesComponent,
    EqualTextValidatorDirective,
    OnlyNumber,
    RecuperarPassComponent,
    ClaveUnicaComponent,
    MisDatosComponent,
    PageNotFoundComponent,
    RedireccionarComponent,
    BusquedaEstacionesComponent,
    ListadoEstacionesComponent,
    EstacionComponent,
    BusquedaElectrolinerasComponent,
    EstacionesFavoritasComponent,
    PoliticaPrivacidadComponent,
    GlosarioComponent,
    ComunalComponent,
    RegionalComponent,
    ZonalComponent,
    FooterComponent,
    ReporteVariacionPreciosComponent,
    ReporteVariacionPreciosDetalleComponent,
    ModalDetalleEstacionComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    FontAwesomeModule,
    NgxLoadingModule.forRoot({backdropBorderRadius: '3px'}),
    AngularMultiSelectModule,
    NgSelectModule,
    NgxMapboxGLModule.withConfig({
        accessToken: 'pk.eyJ1IjoiY29uaWl0YSIsImEiOiJjandjZG92YjQwdW1kNDJuMDN4cWgwZ2U1In0._hqKsmAwQRl1qm1Jn3Yt-g',
        geocoderAccessToken: 'pk.eyJ1IjoiY29uaWl0YSIsImEiOiJjandjZG92YjQwdW1kNDJuMDN4cWgwZ2U1In0._hqKsmAwQRl1qm1Jn3Yt-g'
    }),
    ToastrModule.forRoot(),
    NgxSocialButtonModule,
    MomentModule,
    DataTableModule, DataTablesModule,
    DeviceDetectorModule.forRoot(),
    FormatearPrecioPipeModule.forRoot(),
    AppRoutingModule
  ],
  exports: [
  	EqualTextValidatorDirective,
    OnlyNumber
  ],
  providers: [
  	{provide: LOCALE_ID, useValue: 'es-CL'},
    {provide: Config, useFactory: () => Config.getInstance('config.json')},
    {provide: SocialServiceConfig, useFactory: getAuthServiceConfigs},
    HttpUrlEncodingCodec,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        library.add(faBars);
        library.add(faGasPump);
        library.add(faChevronRight);
        library.add(faChargingStation);
        library.add(faFileAlt);
        library.add(faCheckCircle);
        library.add(faExclamationTriangle);
        library.add(faTimesCircle);
        library.add(faEdit);
        library.add(faStar);
        library.add(farStar);
        library.add(faSignOutAlt);
        library.add(faCrosshairs);
        library.add(faFilter);
        library.add(faArrowLeft);
        library.add(faSortNumericDown);
        library.add(faHeart);
        library.add(farHeart);
        library.add(faHeartBroken);
        //library.add(farHeartBroken);
        library.add(faAngleRight);
        library.add(faFacebook);
        library.add(faTwitterSquare);
        library.add(faClock);
        library.add(faConciergeBell);
        library.add(faMoneyBillAlt);
        library.add(faAward);
        library.add(faShareAlt);
        library.add(faSortNumericUpAlt);
        library.add(faBolt);
        library.add(faMapMarkerAlt);
        library.add(faBan);
        library.add(faTools);
        library.add(faUserCheck);
        library.add(faDownload);
        library.add(faFilePdf);
        library.add(faFileExcel);
        library.add(faChartBar);
        library.add(faSearch);
        library.add(faInfoCircle);
        library.add(faPhoneSquareAlt);
        library.add(faExclamationCircle);
        library.add(faPlus);
        library.add(faUser);
        library.add(faLocationArrow);
        library.add(faWindowClose);
        library.add(faTimes);
    }
}
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
