import * as _ from "lodash";
import {Pipe, PipeTransform} from "@angular/core";
import { Config } from "../app.config";

@Pipe({
	name: 'formatearPrecio'
})
export class FormatearPrecioPipe implements PipeTransform {

    configuraciones;

	constructor(public config: Config) {
        this.configuraciones = this.config.data;
    }

    transform(value:any) {
        if(value === undefined || value === null){
          return 'N/D';
        }
    	  let valor_string = value.toString();
        let arreglo_precio = valor_string.split(".");
        let precio: string = ""
        if(arreglo_precio.length == 1) arreglo_precio.push("0");
        if(this.configuraciones.general.considerar_decimales) {
            if(this.configuraciones.general.esconder_decimal_cero) {
                if(parseFloat(arreglo_precio[1]) <= 0) {
                    precio = parseFloat(arreglo_precio[0]).toLocaleString('en').replace(/,/g, '.');
                } else {
                    precio = parseFloat(arreglo_precio[0]).toLocaleString('en').replace(/,/g, '.')+this.configuraciones.general.separador_decimal+arreglo_precio[1].substring(0,this.configuraciones.general.cantidad_decimales);
                }
            } else {
                precio = parseFloat(arreglo_precio[0]).toLocaleString('en').replace(/,/g, '.')+this.configuraciones.general.separador_decimal+arreglo_precio[1].substring(0,this.configuraciones.general.cantidad_decimales);
            }
        } else {
            precio = parseFloat(arreglo_precio[0]).toLocaleString('en').replace(/,/g, '.');
        }
        return precio;
    }

}
