import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ToastrService } from 'ngx-toastr';
import { Config } from "../app.config";
import { MarcaService } from '../services/marca.service';
import { EstacionService } from '../services/estacion.service';

@Component({
  selector: 'app-estaciones-favoritas',
  templateUrl: './estaciones-favoritas.component.html',
  styleUrls: ['./estaciones-favoritas.component.css']
})
export class EstacionesFavoritasComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    loading:boolean = false;
    estaciones = []; estaciones_lista = []; estacion:any = false; estaciones_mantencion_lista = [];
    markers = []; marker_estacion:any;
    marcasList = []; marcas_logos:any = [];
    total_estaciones: number = 0;

    map: mapboxgl.Map; geojson;
    bounds_options:any = { padding: 100 };
    style = '';
    mapZoom = 0;
    latitud_storage = 0; longitud_storage = 0;
    latitud_defecto = ''; longitud_defecto ='';
    ver_mapa:boolean = true; ver_estacion:boolean = false; ver_resultados:boolean = false;  // Estas banderas se utilizan para la versión movil


    constructor(public config: Config, private marcaService: MarcaService, private estacionService: EstacionService, private toastr: ToastrService) { }

    ngOnInit() {
        this.configuraciones = this.config.data;
        this.longitud_defecto = this.configuraciones.map.longitud_defecto;
        this.latitud_defecto = this.configuraciones.map.latitud_defecto;
        (mapboxgl as any).accessToken = this.configuraciones.map.token;

        this.loading = true;
        this.mapZoom = this.configuraciones.map.zoom;
        this.style = this.configuraciones.map.style;
  
        // Inicializar el mapa  
        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style
        });

    }

    ngAfterViewInit() {
        // Obtener todas las marcas para mostrar logos en mapa
        this.marcaService.getMarcas()
            .subscribe(marcas => {
                if(marcas) {
                    if(marcas.mensaje) {
                        this.loading = false;
                    } else if(marcas) {
                        this.marcas_logos = [];
                        marcas.data.forEach((marca, index) => {
                            marca['inicial'] = marca.nombre.substring(0, 1);
                            this.marcas_logos[marca.id] = {logo: marca.logo, nombre: marca.nombre};
                        });
                        this.marcasList = marcas.data;
                        this.obtenerListadoApi();
                        this.map.resize();
                    }
                }
            });
    }

    obtenerListadoApi() {
        // Quitar si existe la capa y la fuente
        if(this.map.getLayer('clusters')) this.map.removeLayer('clusters');
        if(this.map.getLayer('cluster-count')) this.map.removeLayer('cluster-count');
        if(this.map.getLayer('points')) this.map.removeLayer('points');
        if(this.map.getSource('points')) this.map.removeSource('points');
        this.estaciones = []; this.estaciones_lista = []; this.estaciones_mantencion_lista = []; this.estacion = false;

        // Obtener listado de estaciones de servicio
        this.loading = true;
        this.estacionService.getEstacionesFavoritas()
            .subscribe(estaciones => {
                if(estaciones) {
                    if(estaciones.mensaje) {
                        this.loading = false;
                        this.toastr.error('Ha ocurrido un problema al intentar obtener las Estaciones de Servicio favoritas.', '');
                    } else if(estaciones) {
                        this.estaciones = estaciones; this.total_estaciones = this.estaciones['data'].length;

                        // Obtener el combustible si se ha realizado una búsqueda previa en el navegador y utilizar para realizar búsqueda
                        if(localStorage.getItem('busqueda_estaciones_latitud')) {
                            this.longitud_storage = +localStorage.getItem('busqueda_estaciones_longitud');
                            this.latitud_storage = +localStorage.getItem('busqueda_estaciones_latitud');
                            this.setearMapa();
                        } else {
                            if (navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition(position => {
                                    this.longitud_storage = position.coords.longitude;
                                    this.latitud_storage = position.coords.latitude;
                                    this.setearMapa(this.longitud_storage, this.latitud_storage);
                                }, error => {
                                    localStorage.setItem('busqueda_estaciones_longitud', this.longitud_defecto);
                                    localStorage.setItem('busqueda_estaciones_latitud', this.latitud_defecto);
                                    this.longitud_storage = +localStorage.getItem('busqueda_estaciones_longitud');
                                    this.latitud_storage = +localStorage.getItem('busqueda_estaciones_latitud');
                                    this.setearMapa();
                                });
                            } else {console.log('No geolocation habilitada');}
                        }
                    }
                } else {
                    this.loading = false;
                    this.toastr.error('Ha ocurrido un problema al intentar obtener las Estaciones de Servicio favoritas.', '');
                }
            });
    }

    setearMapa(usuario_longitud = 0, usuario_latitud = 0) { 
        this.loading = true;
        this.map.setZoom(this.mapZoom);

        // Si se obtuvo ubicación del usuario mostrar marcador en el mapa
        if(usuario_longitud != 0) {   
            this.map.setCenter([usuario_longitud, usuario_latitud]);
            let el = document.createElement('div');
            let html = '<img src="/assets/img/map-marker-alt-solid.svg"></img>';
            el.innerHTML = html;
            el.className = 'marker-usuario-ubicacion';
             
            // add marker to map
            new mapboxgl.Marker(el)
                        .setLngLat([usuario_longitud, usuario_latitud])
                        .addTo(this.map);
        } else {
            //this.map.setCenter([this.longitud_storage, this.latitud_storage]);
        }

        this.geojson = {
                        "type": "FeatureCollection",
                        "features": [ ]
                };
        // Desde la data obtenida de la api generar data para formar el layer en el mapa
        this.estaciones['data'].forEach((estacion, index) => {
            if(estacion.latitud >= -90 && estacion.latitud <= 90 && estacion.longitud >= -180 && estacion.longitud <= 180) {
                this.geojson['features'].push({"type": "Feature",
                                        "geometry": {
                                            "type": "Point",
                                            "coordinates": [estacion.longitud, estacion.latitud]
                                        },
                                        "properties": {
                                            "id": estacion.id,
                                            "nombre": (this.marcas_logos[estacion.marca] ? this.marcas_logos[estacion.marca]['nombre'] : ""),
                                            "logo": (this.marcas_logos[estacion.marca] ? this.marcas_logos[estacion.marca]['logo'] : ""),
                                            "direccion": estacion.direccion,
                                            "comuna": estacion.comuna,
                                            "region": estacion.region,
                                            "latitud": estacion.latitud,
                                            "longitud": estacion.longitud,
                                            "electrolinera_bandera": estacion.electrolinera_bandera,
                                            "gasolinera_bandera": estacion.gasolinera_bandera,
                                            "control_calidad": estacion.control_calidad,
                                            "usuario_favorito": estacion.usuario_favorito,
                                            "usuario_likes": estacion.usuario_likes,
                                            "likes": estacion.likes,
                                            "dislikes": estacion.dislikes,
                                            "combustibles": estacion.combustibles,
                                            "en_mantenimiento_bandera": estacion.en_mantenimiento_bandera,
                                            "marca": estacion.marca
                                        }});
            }
        });
        // Quitar si existe la capa y la fuent
        if(this.map.getLayer('points')) this.map.removeLayer('points');
        if(this.map.getSource('points')) this.map.removeSource('points');
        if(this.map.getLayer('clusters')) this.map.removeLayer('clusters');
        if(this.map.getLayer('cluster-count')) this.map.removeLayer('cluster-count');
       
        let options_add_source:any = { type: 'geojson', data: this.geojson, cluster: true, clusterMaxZoom: 14, clusterRadius: 30 };
        this.map.addSource('points', options_add_source);

        // Agregar capa para agrupar
        this.map.addLayer({
            id: "clusters",
            type: "circle",
            source: "points",
            filter: ["has", "point_count"],
            paint: {
                "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#2B4780",
                    100,
                    "#2B4780",
                    750,
                    "#2B4780"
                ],
                "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        });
        
        // Agregar capa para mostrar la cantidad agrupada
        this.map.addLayer({
            id: "cluster-count",
            interactive: true,
            type: "symbol",
            source: "points",
            filter: ["has", "point_count"],
            layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
            },
            paint: {
                "text-color": "#FFFFFF"
            }
        });

        // Agregar layer en mapa para las estaciones para así luego poder obtener los puntos que se ven en pantalla
        this.map.addLayer({
            "id": "points",
            "type": "line",
            "source": "points",
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
                "paint": {
                "line-color": "#ff69b4",
                "line-width": 1
            }
        });

        if( this.geojson['features'].length > 0) {
            // Mostrar todos los marcadores en el mapa a la vez
            let bounds = new mapboxgl.LngLatBounds();

            this.geojson.features.forEach(function(feature) {
                bounds.extend(feature.geometry.coordinates);
            });
            //let bounds_options:any = { padding: 100 };
            this.map.fitBounds(bounds, this.bounds_options);
        } else {
            this.map.setCenter([this.configuraciones.map.longitud_defecto, this.configuraciones.map.latitud_defecto]);
        }

        // Cada vez que se mueve en el mapa o se hace un zoom obtener los marcadores que se ven en pantalla
        this.map.on('idle', idlemap => {
            this.estaciones_lista = []; this.estaciones_mantencion_lista = [];
            let options_points_in_layer:any = {layers: ['points']};
            let points_in_layer = this.map.queryRenderedFeatures(options_points_in_layer);
            let arreglo_estacion:any;

            this.removerMarcadores();

            if(!this.ver_estacion) {
                points_in_layer.forEach((point, index) => {
                    if(point.properties.latitud) {
                        arreglo_estacion = {
                                            "id": point.properties.id,
                                            "nombre": point.properties.nombre,
                                            "logo": point.properties.logo,
                                            "direccion": point.properties.direccion,
                                            "comuna": point.properties.comuna,
                                            "region": point.properties.region,
                                            "latitud": point.properties.latitud,
                                            "longitud": point.properties.longitud,
                                            "electrolinera_bandera": point.properties.electrolinera_bandera,
                                            "gasolinera_bandera": point.properties.gasolinera_bandera,
                                            "control_calidad": JSON.parse(point.properties.control_calidad),
                                            "usuario_favorito": JSON.parse(point.properties.usuario_favorito),
                                            "usuario_likes": JSON.parse(point.properties.usuario_likes),
                                            "likes": point.properties.likes,
                                            "dislikes": point.properties.dislikes,
                                            "combustibles": JSON.parse(point.properties.combustibles),
                                            "en_mantenimiento_bandera": point.properties.en_mantenimiento_bandera,
                                            "marca": point.properties.marca
                                        };
                        if(point.properties.en_mantenimiento_bandera) this.estaciones_mantencion_lista.push(arreglo_estacion);
                        else this.estaciones_lista.push(arreglo_estacion);
                        let el = document.createElement('div');
                         // llamar a api para obtener la imagen del logo de la marca
                        let logobase64 = "";
                        this.marcaService.getMarcaLogo(point.properties.marca)
                                        .subscribe(logo => {
                                            let html = "";
                                            if(logo) {
                                                logobase64 = logo[0];
                                                html = '<div id="'+point.properties.id+'"><img id="'+point.properties.id+'" class="estacion-logo" src="data:image/png;charset=utf-8;base64,'+logobase64+'" alt=""></div>';
                                            } else {
                                                html = '<div id="'+point.properties.id+'"></div>';
                                            }
                                            el.innerHTML = html;
                                            el.className = 'marker ';
                                            el.id = point.properties.id;
                                            // add marker to map
                                            let marker = new mapboxgl.Marker(el)
                                                        .setLngLat([point.properties.longitud, point.properties.latitud])
                                                        .addTo(this.map);

                                            this.markers.push(marker);
                                        });
                    }
                });
            }
        });
        this.map.on('load', loadmap => {
            
        });
        // inspect a cluster on click
        /*this.map.on('click', points => {
            this.onSeleccionarEstacion(points['originalEvent']['originalTarget']['id']);
        }); */
        // inspect a cluster on click
        this.map.on('click', clusters => {
            if(clusters['originalEvent']['srcElement']['className'] == 'mapboxgl-canvas') {
                var clusterId:any = clusters.point;
                const source: mapboxgl.GeoJSONSource = this.map.getSource('points') as mapboxgl.GeoJSONSource;
                source.getClusterExpansionZoom(clusterId, zoom => {
                    this.map.easeTo({
                        center: clusters.lngLat,
                        zoom: this.map.getZoom()+4
                    });
                });
            } else {
                if(!this.ver_estacion) {
                    this.onSeleccionarEstacion(clusters['originalEvent']['srcElement']['id']);
                }
            }
        }); 
        this.map.on('mouseenter', 'clusters', mouseenter => {
            this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'clusters', mouseleave => {
            this.map.getCanvas().style.cursor = '';
        });

        this.loading = false;
    }

    onSeleccionarEstacion(id) {
        this.loading = true; this.ver_resultados = false;             
        this.estacionService.getEstacion(id)
            .subscribe(estacion => {
                if(estacion) {
                    if(estacion.mensaje) {
                        this.loading = false;
                        this.toastr.error('Ha ocurrido un problema al intentar obtener los datos de la Estación de Servicio.', '');
                    } else if(estacion) {
                        this.estacion = estacion.data;
                        this.loading = false;
                    }
                }
            });
    }

    onMostrarTodasLasEstacionesFavoritas() {
        // Mostrar todos los marcadores en el mapa a la vez
        let bounds = new mapboxgl.LngLatBounds();

        this.geojson.features.forEach(function(feature) {
            bounds.extend(feature.geometry.coordinates);
        });
        this.map.fitBounds(bounds, this.bounds_options);
    }

    removerMarcadores() {
        this.markers.forEach((marker, index) => {
            marker.remove();
        });
    }

    setearMarcasEnMapa(geojson) {
        geojson.features.forEach((marker, index) => {
            let el = document.createElement('div');
            let html = "";
            if(marker.properties.class == "usuario-ubicacion") {
                html = '<img class="text-danger" src="/assets/img/map-marker-alt-solid.svg"></img>';
                el.innerHTML = html;
                el.className = 'marker-'+ marker.properties.class;
            } else {
                 // llamar a api para obtener la imagen del logo de la marca
                let logobase64 = "";
                this.marcaService.getMarcaLogo(this.estacion.marca)
                                .subscribe(logo => {
                                    html = "";
                                    if(logo) {
                                        logobase64 = logo[0];
                                        html = '<img class="estacion-logo" src="data:image/png;charset=utf-8;base64,'+logobase64+'" alt="">';
                                    }
                                    el.innerHTML = html;
                                    el.className = 'marker '+ marker.properties.class;
                                });
            }
             
            // add marker to map
            this.marker_estacion = new mapboxgl.Marker(el)
                        .setLngLat(marker.geometry.coordinates)
                        .addTo(this.map);
        });
    }

    volverAListado() {
        this.ver_estacion = false; this.marker_estacion.remove();
        this.obtenerListadoApi();
    }

    usarUbicacionUsuario() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.longitud_storage = position.coords.longitude;
                this.latitud_storage = position.coords.latitude;
                localStorage.setItem('busqueda_estaciones_longitud', this.longitud_storage.toString());
                localStorage.setItem('busqueda_estaciones_latitud', this.latitud_storage.toString());
                this.setearMapa(this.longitud_storage, this.latitud_storage);
            }, error => {
                this.toastr.error('El navegador no tiene permisos para localizarte.', '');
            });
        } else {console.log('No geolocation habilitada');}
    }

    onModalClose() {
        this.estacion = false;
    }

}
