import { Component, OnInit, Output, EventEmitter, AfterViewInit, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ToastrService } from 'ngx-toastr';
import { Config } from "../app.config";
import { CombustibleService } from '../services/combustible.service';
import { MarcaService } from '../services/marca.service';
import { ServicioService } from '../services/servicio.service';
import { EstacionService } from '../services/estacion.service';
import { FormatearPrecioPipe } from "../pipes/formatear-precio.pipe";

@Component({
    selector: 'app-busqueda-estaciones',
    templateUrl: './busqueda-estaciones.component.html',
    styleUrls: ['./busqueda-estaciones.component.css'],
    providers: [FormatearPrecioPipe]
})

export class BusquedaEstacionesComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    loading:boolean = false;
    combustible_storage:any = 1; combustible:any;
    estaciones = []; estaciones_lista = []; estacion:any = false; estaciones_todas = []; estaciones_mantencion_lista = [];
    busquedaForm: FormGroup; busquedaMovilForm: FormGroup;
    combustibles = [];
    combustiblesList = [];
    marcasList = []; serviciosList = []; marcas_logos:any;
    marcasSeleccionadas = []; serviciosSeleccionados = []; marcasSeleccionadasArray = []; serviciosSeleccionadosArray = [];
    markers = []; marker_estacion:any;
    precio_minimo:any = 0; precio_maximo:any = 0;

    map: mapboxgl.Map;
    style = '';
    mapZoom = 0;
    latitud_storage = 0; longitud_storage = 0;
    latitud_defecto = ''; longitud_defecto ='';
    ver_mapa:boolean = true; ver_estacion:boolean = false; ver_resultados:boolean = false;  // Estas banderas se utilizan para la versión movil
    volver:boolean = false; // Bandera para setear cuando de click al botón volver
    state: any;

    constructor(public config: Config, private formBuilder: FormBuilder, public combustibleService: CombustibleService, private marcaService: MarcaService, private servicioService: ServicioService, private estacionService: EstacionService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private formatearPrecioPipe: FormatearPrecioPipe) {}

    ngOnInit() {
        this.loading = true;
        this.configuraciones = this.config.data;
        this.longitud_defecto = this.configuraciones.map.longitud_defecto;
        this.latitud_defecto = this.configuraciones.map.latitud_defecto;
        (mapboxgl as any).accessToken = this.configuraciones.map.token;

        /*Crea evento para abrir o cerrar menu de bencinas*/
        $(".menu-toggle").on("click",function(){
            $(".menu-toggle").toggleClass('open');
            $(".menu-line").toggleClass('open');
            /*Cambio el contenido del boton del menu*/
            if($(".menu-toggle").hasClass('open')){
                $('.text-menu').addClass('d-none');
                $('.icon-menu').removeClass('d-none');
            }else{
                $('.text-menu').removeClass('d-none');
                $('.icon-menu').addClass('d-none');
            }

        });


        // Obtener filtros si se ha realizado una búsqueda previa en el navegador y utilizar para realizar búsqueda
        if(localStorage.getItem('busqueda_estaciones_combustible')) {
            if(this.route.snapshot.paramMap.get('combustible')) {
                this.combustible_storage = this.route.snapshot.paramMap.get('combustible');
            } else {
                this.combustible_storage = localStorage.getItem('busqueda_estaciones_combustible');
            }
        }
        if(localStorage.getItem('busqueda_estaciones_marcas')) {
            this.marcasSeleccionadasArray = JSON.parse(localStorage.getItem('busqueda_estaciones_marcas'));
        }
        if(localStorage.getItem('busqueda_estaciones_servicios')) {
            this.serviciosSeleccionadosArray = JSON.parse(localStorage.getItem('busqueda_estaciones_servicios'));
            this.serviciosSeleccionadosArray.forEach((item, index) => {
                this.serviciosSeleccionados.push({id: item});
            });
        }

        // Obtener todos los servicios para el filtro
        this.servicioService.getServicios()
            .subscribe(servicios => {
                if(servicios) {
                    if(servicios.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener los servicios.', '');
                        this.loading = false;
                    } else if(servicios) {
                        this.serviciosList = servicios.data;
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener los servicios.', '');
                    this.loading = false;
                }
            });
        this.mapZoom = this.configuraciones.map.zoom;
        this.style = this.configuraciones.map.style;

        // Obtener todos los combustibles para el filtro
        this.combustibleService.getCombustibles()
            .subscribe(combustibles => {
                if(combustibles) {
                    if(combustibles.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
                        this.loading = false;
                    } else if(combustibles) {
                        this.combustiblesList = combustibles.data;

                        //TODO: Evaluar la creacion de nuevo endpoint para agrupar combustibles con mas de un tipo de atencion

                        //Se obtienen combustibles de un solo tipo de servicio: el id 2 tiene todo los tipos de combustibles necesarios
                        combustibles.data.forEach(combustibleX => {
                          if(combustibleX.tipo_atencion == 2) {
                            this.combustibles.push(combustibleX);
                          }
                        });

                        //Tipo de combustibles seleccionado (Id 1 por defecto)
                        this.combustible = [];
                        this.combustibles.some(item => {
                            if(item.id == this.combustible_storage) {
                                this.combustible = item;
                                return true;
                            }
                        });
                        if(this.combustible.length == 0) {
                            if(this.combustibles[0]['id']) {
                                // Si por alguna razón el combustible en sesión ya no está disponible utilizar el primer combustible que venga en el arreglo de combustibles
                                this.combustible_storage = this.combustibles[0]['id'];
                                this.combustible = this.combustibles[0];
                                localStorage.setItem('busqueda_estaciones_combustible', this.combustible_storage);
                            } else {
                                this.state = [{state: "error", origen: "busqueda_estaciones"}];
                                this.loading = false;
                            }
                        }
                        this.busquedaForm.patchValue({combustible: this.combustible_storage});
                        this.busquedaMovilForm.patchValue({combustible: this.combustible_storage});
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
                    this.loading = false;
                }
            });

        this.busquedaForm = this.formBuilder.group({
            combustible: [this.combustible_storage, Validators.required ]
        });

        this.busquedaMovilForm = this.formBuilder.group({
            combustible: [this.combustible_storage, Validators.required ]
        });

        // Inicializar el mapa
        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style
        });

        // Add geocoder control to the map.
        var geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Ingresar dirección',
            countries: 'cl'
        });

        // Add geocoder control to the map.
        var geocoder_movil = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Ingresar dirección',
            countries: 'cl'
        });

        document.getElementById('geocoder').appendChild(geocoder.onAdd(this.map));
        document.getElementById('geocoder-movil').appendChild(geocoder_movil.onAdd(this.map));

        geocoder.on('result', function(result) {
            let element = document.getElementById("veloFull");
            element.classList.remove("show");
            element = document.getElementById("veloFullNav");
            element.classList.remove("show");

            this.longitud_storage = result.result.geometry.coordinates[0];
            this.latitud_storage = result.result.geometry.coordinates[1];
            localStorage.setItem('busqueda_estaciones_longitud', this.longitud_storage);
            localStorage.setItem('busqueda_estaciones_latitud', this.latitud_storage);
        });
        geocoder_movil.on('result', function(result) {
            this.longitud_storage = result.result.geometry.coordinates[0];
            this.latitud_storage = result.result.geometry.coordinates[1];
            localStorage.setItem('busqueda_estaciones_longitud', this.longitud_storage);
            localStorage.setItem('busqueda_estaciones_latitud', this.latitud_storage);
        });
}

    ngAfterViewInit() {
        // Obtener todas las marcas para el filtro
        this.marcaService.getMarcas()
            .subscribe(marcas => {
                if(marcas) {
                    if(marcas.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener las marcas.', '');
                        this.loading = false;
                    } else if(marcas) {
                        this.marcas_logos = [];
                        this.marcasSeleccionadas = [];
                        marcas.data.forEach((marca, index) => {
                            marca['inicial'] = marca.nombre.substring(0, 1);
                            this.marcas_logos[marca.id] = {logo: marca.logo, nombre: marca.nombre};
                            if(this.marcasSeleccionadasArray.indexOf(marca.id) >= 0) {
                                this.marcasSeleccionadas.push({id: marca.id, nombre: marca.nombre});
                            };
                        });
                        this.marcasList = marcas.data;
                        if(!this.route.snapshot.paramMap.get('id')) {
                            this.obtenerListadoApi();
                        }
                        if(this.route.snapshot.paramMap.get('id')) {
                            this.combustible_storage = this.route.snapshot.paramMap.get('combustible');
                            this.onSeleccionarEstacion(this.route.snapshot.paramMap.get('id'));
                        }
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener las marcas.', '');
                    this.loading = false;
                }
            });

        if(!this.route.snapshot.paramMap.get('id')) {
            this.map.resize();
        }
    }

    obtenerListadoApi() {
        // Obtener listado de estaciones de servicio
        this.loading = true;
        this.estacionService.getEstaciones(this.combustible_storage, this.marcasSeleccionadasArray, this.serviciosSeleccionadosArray)
            .subscribe(estaciones => {
                if(estaciones) {
                    if(estaciones.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener las Estaciones de Servicio.', '');
                        this.loading = false;
                    } else if(estaciones) {
                        this.estaciones_todas = estaciones.data;
                        this.filtrar_estaciones(estaciones.data);
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener las Estaciones de Servicio.', '');
                    this.loading = false;
                }
            });
    }

    setearMapa(usuario_longitud = 0, usuario_latitud = 0) {
        this.loading = true;
        this.map.setZoom(this.mapZoom);

        // Si se obtuvo ubicación del usuario mostrar marcador en el mapa
        if(usuario_longitud != 0) {
            this.map.setCenter([usuario_longitud, usuario_latitud]);
            let el = document.createElement('div');
            let html = '<img src="/assets/img/map-marker-alt-solid.svg"></img>';
            el.innerHTML = html;
            el.className = 'marker-usuario-ubicacion';

            // add marker to map
            new mapboxgl.Marker(el)
                        .setLngLat([usuario_longitud, usuario_latitud])
                        .addTo(this.map);
        } else {
            this.map.setCenter([this.longitud_storage, this.latitud_storage]);
        }

        let geojson = {
                        "type": "FeatureCollection",
                        "features": [ ]
                    };
        // Desde la data obtenida de la api generar data para formar el layer en el mapa
        this.estaciones.forEach((estacion, index) => {
            if(estacion.latitud >= -90 && estacion.latitud <= 90 && estacion.longitud >= -180 && estacion.longitud <= 180) {
                geojson['features'].push({"type": "Feature",
                                        "geometry": {
                                            "type": "Point",
                                            "coordinates": [estacion.longitud, estacion.latitud]
                                        },
                                        "properties": {
                                            "id": estacion.id,
                                            "nombre": (this.marcas_logos[estacion.marca] ? this.marcas_logos[estacion.marca]['nombre'] : ""),
//"logo": (this.marcas_logos[estacion.marca] ? this.marcas_logos[estacion.marca]['logo'] : ""),
                                            "direccion": estacion.direccion,
                                            "comuna": estacion.comuna,
                                            "region": estacion.region,
                                            "latitud": estacion.latitud,
                                            "longitud": estacion.longitud,
                                            "electrolinera_bandera": estacion.electrolinera_bandera,
                                            "gasolinera_bandera": estacion.gasolinera_bandera,
                                            "control_calidad": estacion.control_calidad,
                                            "servicios": estacion.servicios,
                                            "usuario_favorito": estacion.usuario_favorito,
                                            "usuario_likes": estacion.usuario_likes,
                                            "likes": estacion.likes,
                                            "dislikes": estacion.dislikes,
                                            "combustibles": estacion.combustibles,
                                            "combustible": estacion.combustible,
                                            "combustible1": estacion.combustible1 !== undefined ? estacion.combustible1 : null,
                                            "combustible2": estacion.combustible2 !== undefined ? estacion.combustible2 : null,
                                            "id_combustibles":estacion.id_combustibles,
                                            "en_mantenimiento_bandera": estacion.en_mantenimiento_bandera,
                                            "marca": estacion.marca,
                                            "atenciones":estacion.atenciones,
                                            "logo": estacion.logo
                                        }});
            }
        });
        // Quitar si existe la capa y la fuent
        if(this.map.getLayer('points')) this.map.removeLayer('points');
        if(this.map.getLayer('clusters')) this.map.removeLayer('clusters');
        if(this.map.getLayer('cluster-count')) this.map.removeLayer('cluster-count');
        if(this.map.getSource('points')) this.map.removeSource('points');

        let options_add_source:any = { type: 'geojson', data: geojson, cluster: true, clusterMaxZoom: 14, clusterRadius: 30 };
        this.map.addSource('points', options_add_source);

        // Agregar capa para agrupar
        this.map.addLayer({
            id: "clusters",
            type: "circle",
            source: "points",
            filter: ["has", "point_count"],
            paint: {
                "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#2B4780",
                    100,
                    "#2B4780",
                    750,
                    "#2B4780"
                ],
                "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        });

        // Agregar capa para mostrar la cantidad agrupada
        this.map.addLayer({
            id: "cluster-count",
            interactive: true,
            type: "symbol",
            source: "points",
            filter: ["has", "point_count"],
            layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
            },
            paint: {
                "text-color": "#FFFFFF"
            }
        });

        // Agregar layer en mapa para las estaciones para así luego poder obtener los puntos que se ven en pantalla
        this.map.addLayer({
            "id": "points",
            "type": "line",
            "source": "points",
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
                "paint": {
                "line-color": "#ff69b4",
                "line-width": 1
            }
        });
        // Cada vez que se mueve en el mapa o se hace un zoom obtener los marcadores que se ven en pantalla
        this.map.on('idle', idlemap => {
            this.estaciones_lista = []; this.estaciones_mantencion_lista = [];
            let options_points_in_layer:any = {layers: ['points']};
            let points_in_layer = this.map.queryRenderedFeatures(options_points_in_layer);

            this.removerMarcadores();

            if(!this.ver_estacion) {
                let precio_minimo:number = 9999999; let precio_maximo:number = 0;
                let arreglo_estacion:any;
                points_in_layer.forEach((point, index) => {
                    if(point.properties.latitud) {
                        arreglo_estacion = {
                                            "id": point.properties.id,
                                            "nombre": point.properties.nombre,
                                            "logo": point.properties.logo,
                                            "direccion": point.properties.direccion,
                                            "comuna": point.properties.comuna,
                                            "region": point.properties.region,
                                            "latitud": point.properties.latitud,
                                            "longitud": point.properties.longitud,
                                            "electrolinera_bandera": point.properties.electrolinera_bandera,
                                            "gasolinera_bandera": point.properties.gasolinera_bandera,
                                            "control_calidad": JSON.parse(point.properties.control_calidad),
                                            "servicios": JSON.parse(point.properties.servicios),
                                            "usuario_favorito": JSON.parse(point.properties.usuario_favorito),
                                            "usuario_likes": JSON.parse(point.properties.usuario_likes),
                                            "likes": point.properties.likes,
                                            "dislikes": point.properties.dislikes,
                                            "combustibles": JSON.parse(point.properties.combustibles),
                                            "combustible": JSON.parse(point.properties.combustible),
                                            "combustible1": point.properties.combustible1 !== null ? JSON.parse(point.properties.combustible1) : null,
                                            "combustible2": point.properties.combustible1 !== null ? JSON.parse(point.properties.combustible2) : null,
                                            "id_combustibles": JSON.parse(point.properties.id_combustibles),
                                            "en_mantenimiento_bandera": point.properties.en_mantenimiento_bandera,
                                            "marca": point.properties.marca,
                                        };
                        if(point.properties.en_mantenimiento_bandera) this.estaciones_mantencion_lista.push(arreglo_estacion);
                        else {
                         this.estaciones_lista.push(arreglo_estacion);
                        }

                        let el = document.createElement('div');
                        let combustible = JSON.parse(point.properties.combustible);
                        let precio: string = ""
                        if(!point.properties.en_mantenimiento_bandera) {
                            precio = this.formatearPrecioPipe.transform(combustible.precio);
                        }
                        let unidad_cobro: string = (!point.properties.en_mantenimiento_bandera ? combustible.unidad_cobro : "");
                        // llamar a api para obtener la imagen del logo de la marca
                        let logobase64 = "";
//this.marcaService.getMarcaLogo(point.properties.marca)
                                        //.subscribe(logo => {
                        let html = "";
                        if(point.properties.logo) {
                            logobase64 = point.properties.logo;
                            //html = '<div id="'+point.properties.id+'"><img id="'+point.properties.id+'" class="estacion-logo" src="data:image/png;charset=utf-8;base64,'+logobase64+'" alt=""><p id="'+point.properties.id+'" class="precio">'+precio+' '+unidad_cobro+'</p></div>';

                            //Agrega Icono segun tipo de combustible
                            //Buscar AAA
                            let combustiblesTodos=JSON.parse(point.properties.combustibles);
                            let combustibleOne=JSON.parse(point.properties.combustible);
                            let combustiblesSeleccionados=JSON.parse(point.properties.id_combustibles);
                            html = '<div id="'+point.properties.id+'"><img id="'+point.properties.id+'" class="estacion-logo" src="'+logobase64+'" alt="">' ;
                            combustiblesTodos.forEach((comb) => {

                              //Se añaden valores del combustible con todos todos los tipos de servicios existentes en la estacion
                              if (combustiblesSeleccionados.includes(comb.id)) {
                                if(comb.precio > 0) {
                                  if (comb.tipo_atencion == 1) {
                                      html += '<p id="' + point.properties.id + '" class="precio"><img src="/assets/img/logo-asistido.png" alt="" style="height:12px;width:12px;margin-top:-5px">&nbsp;' + this.formatearPrecioPipe.transform(comb.precio) + ' ' + comb.unidad_cobro + '</p> ';
                                  } else {
                                      html += '<p id="' + point.properties.id + '" class="precio"><img src="/assets/img/logo-autoservicio.png" alt="" style="height:12px;width:12px;margin-top:-5px">&nbsp;' + this.formatearPrecioPipe.transform(comb.precio) + ' ' + comb.unidad_cobro + '</p> ';
                                  }
                                }
                              }

                            });
                            html += '</div>';
                        }
                        else {
                            html = '<div id="'+point.properties.id+'"><p id="'+point.properties.id+'" class="precio">'+precio+' '+unidad_cobro+'</p></div>';
                        }
                        el.innerHTML = html;
                        el.className = 'marker ';
                        el.id = point.properties.id;
                        // add marker to map
                        let marker = new mapboxgl.Marker(el)
                                    .setLngLat([point.properties.longitud, point.properties.latitud])
                                    .addTo(this.map);

                        this.markers.push(marker);
//});
                        if(precio_minimo > +combustible.precio) precio_minimo = +combustible.precio;
                        if(precio_maximo < +combustible.precio) precio_maximo = +combustible.precio;

                    }
                });
                if(precio_minimo == 9999999) precio_minimo = 0;
                this.precio_minimo = this.formatearPrecioPipe.transform(points_in_layer.length == 0 ? 0 : precio_minimo.toString());
                this.precio_maximo = this.formatearPrecioPipe.transform(precio_maximo.toString());
            }
            this.loading = false;
        });
        this.map.on('load', loadmap => {

        });
        // inspect a cluster on click
        this.map.on('click', clusters => {
            if(clusters['originalEvent']['srcElement']['className'] == 'mapboxgl-canvas') {
                var clusterId:any = clusters.point;
                const source: mapboxgl.GeoJSONSource = this.map.getSource('points') as mapboxgl.GeoJSONSource;
                source.getClusterExpansionZoom(clusterId, zoom => {
                    this.map.easeTo({
                        center: clusters.lngLat,
                        zoom: this.map.getZoom()+4
                    });
                });
            } else {
                if(!this.ver_estacion) {
                    this.onSeleccionarEstacion(clusters['originalEvent']['srcElement']['id']);
                }
            }
        });
        this.map.on('mouseenter', 'clusters', mouseenter => {
            this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'clusters', mouseleave => {
            this.map.getCanvas().style.cursor = '';
        });
    }

    onSubmitBusqueda(busquedaForm) {
        this.cerrarMenu();
        if(this.route.snapshot.paramMap.get('id')) {
            this.router.navigate(['/busqueda_estaciones']);
        } else {
            this.loading = true; this.state = false; this.ver_estacion = false;
            this.combustible_storage = busquedaForm.combustible;
            if(this.marker_estacion) this.marker_estacion.remove();
            localStorage.setItem('busqueda_estaciones_combustible', busquedaForm.combustible);
            localStorage.setItem("busqueda_estaciones_marcas", JSON.stringify(this.marcasSeleccionadasArray));
            localStorage.setItem("busqueda_estaciones_servicios", JSON.stringify(this.serviciosSeleccionadosArray));

            this.combustibles.some(item => {
                if(item.id == this.combustible_storage) {
                    this.combustible = item;
                    return true;
                }
            });
            this.filtrar_estaciones(this.estaciones_todas);
        }
    }

    onSeleccionarEstacion(id) {
        this.loading = true; this.ver_resultados = false;
        this.estacionService.getEstacion(id)
            .subscribe(estacion => {
                if(estacion) {
                    if(estacion.message || estacion.data.message) {
                        this.state = [{state: "error", origen: "busqueda_estaciones"}];
                        this.loading = false;
                    } else if(estacion) {
                        this.estacion = estacion.data;
                        this.loading = false;
                        //this.volver = true;
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener los datos de la Estación de Servicio.', '');
                    this.loading = false;
                }
            });
    }

    removerMarcadores() {
        this.markers.forEach((marker, index) => {
            marker.remove();
        });
    }

    setearMarcasEnMapa(geojson) {
        let precio = ""; let unidad_cobro = ""; let arreglo_precio = [];let combustiblesAll = []; let nombre_corto = "";
        if(!this.estacion.en_mantenimiento_bandera) {
            this.estacion.combustibles.some(combustible => {
                if(combustible.id == this.combustible_storage) {
                    precio = this.formatearPrecioPipe.transform(combustible.precio);
                    unidad_cobro = combustible.unidad_cobro;
                    nombre_corto =  combustible.nombre_corto;
                    combustiblesAll = combustible.nombre_corto_grupo;
                    return true;
                }
            });
        }
        geojson.features.forEach((marker, index) => {
            let el = document.createElement('div');
            let html = "";
            if(marker.properties.class == "usuario-ubicacion") {
                html = '<img class="text-danger" src="/assets/img/map-marker-alt-solid.svg"></img>';
                el.innerHTML = html;
                el.className = 'marker-'+ marker.properties.class;
            } else {
                let logobase64 = "";
                // llamar a api para obtener la imagen del logo de la marca
                this.marcaService.getMarcaLogo(this.estacion.marca)
                                .subscribe(logo => {
// if(logo) {
                                    //     logobase64 = logo[0];
                                    //     html = '<img id="'+this.estacion.id+'" class="estacion-logo" src="data:image/png;charset=utf-8;base64,'+logobase64+'" alt=""><p id="'+this.estacion.id+'" class="precio">'+precio+' '+unidad_cobro+'</p>';
                                    // }

                                    if(logo) {
                                        logobase64 = logo[0];

                                        //Agrega Icono segun tipo de combustible
                                        //Buscar CCC
                                        let combustiblesTodos=combustiblesAll;
                                        let combustibleOne=nombre_corto;
                                        html = '<div><img id="" class="estacion-logo" src="'+logobase64+'" alt="">' ;
                                        combustiblesTodos.forEach((comb) => {
                                                if(comb.nombre_corto==combustibleOne){
                                                    if(comb.precio > 0) {
                                                        if (comb.tipo_atencion == 1) {
                                                        html += '<p id="' + this.estacion.id + '" class="precio"><img src="/assets/img/logo-asistido.png" alt="" style="height:12px;width:12px;margin-top:-5px">&nbsp;' + this.formatearPrecioPipe.transform(comb.precio) + ' ' + comb.unidad_cobro + '</p> ';
                                                        } else {
                                                        html += '<p id="' + this.estacion.id + '" class="precio"><img src="/assets/img/logo-autoservicio.png" alt="" style="height:12px;width:12px;margin-top:-5px">&nbsp;' + this.formatearPrecioPipe.transform(comb.precio) + ' ' + comb.unidad_cobro + '</p> ';
                                                        }
                                                    }
                                                }
                                            });
                                            html += '</div>';
                                    }
                                    else {
                                        html = '<p id="'+this.estacion.id+'" class="precio">'+precio+' '+unidad_cobro+'</p>';
                                    }
                                    el.innerHTML = html;
                                    el.className = 'marker '+ marker.properties.class;
                                });
            }

            // add marker to map
            this.marker_estacion = new mapboxgl.Marker(el)
                        .setLngLat(marker.geometry.coordinates)
                        .addTo(this.map);
        });
    }

    volverAListado() {
        if(this.route.snapshot.paramMap.get('id') && !this.volver) {
            this.ver_estacion = false; this.estacion = false; this.marker_estacion.remove();
            this.obtenerListadoApi();
        } else {
            this.ver_estacion = false; this.estacion = false; this.marker_estacion.remove();
            // Obtener el combustible si se ha realizado una búsqueda previa en el navegador y utilizar para realizar búsqueda
            if(localStorage.getItem('busqueda_estaciones_latitud')) {
                this.longitud_storage = +localStorage.getItem('busqueda_estaciones_longitud');
                this.latitud_storage = +localStorage.getItem('busqueda_estaciones_latitud');
                this.setearMapa();
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.longitud_storage = position.coords.longitude;
                        this.latitud_storage = position.coords.latitude;
                        this.setearMapa(this.longitud_storage, this.latitud_storage);
                    }, error => {
                        localStorage.setItem('busqueda_estaciones_longitud', this.longitud_defecto);
                        localStorage.setItem('busqueda_estaciones_latitud', this.latitud_defecto);
                        this.longitud_storage = +localStorage.getItem('busqueda_estaciones_longitud');
                        this.latitud_storage = +localStorage.getItem('busqueda_estaciones_latitud');
                        this.setearMapa();
                    });
                } else {console.log('No geolocation habilitada');}
            }
        }
        this.volver = true;
    }

    usarUbicacionUsuario() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.longitud_storage = position.coords.longitude;
                this.latitud_storage = position.coords.latitude;
                localStorage.setItem('busqueda_estaciones_longitud', this.longitud_storage.toString());
                localStorage.setItem('busqueda_estaciones_latitud', this.latitud_storage.toString());
                this.setearMapa(this.longitud_storage, this.latitud_storage);
            }, error => {
                this.toastr.error('El navegador no tiene permisos para localizarte.', '');
                this.loading = false;
            });
        } else {console.log('No geolocation habilitada');}
    }

    seleccionarTodasLasMarcas(sel) {
        if(sel) {
            this.marcasSeleccionadasArray = [];
            this.marcasList.forEach((item, index) => {
                this.marcasSeleccionadasArray.push(item.id);
            });
            this.marcasSeleccionadas = this.marcasList;
        } else {
            this.marcasSeleccionadasArray = [];
            this.marcasSeleccionadas = [];
        }
    }

    compararMarcas = (item, selected) => {
        if (item.id && selected.id) {
            return item.id === selected.id;
        }
        return false;
    };

    seleccionarTodosLosServicios(sel) {
        if(sel) {
            this.serviciosSeleccionadosArray = [];
            this.serviciosList.forEach((item, index) => {
                this.serviciosSeleccionadosArray.push(item.id);
            });
            this.serviciosSeleccionados = this.serviciosList;
        } else {
            this.serviciosSeleccionadosArray = [];
            this.serviciosSeleccionados = [];
        }
    }

    compararServicios = (item, selected) => {
        if (item.id && selected.id) {
            return item.id === selected.id;
        }
        return false;
    };

    onChangeMarca($event) {
        var event = $event;
        this.marcasSeleccionadasArray = [];
        event.forEach((item, index) => {
            this.marcasSeleccionadasArray.push(item.id);
        });
    }

    onChangeServicio($event) {
        var event = $event;
        this.serviciosSeleccionadosArray = [];
        event.forEach((item, index) => {
            this.serviciosSeleccionadosArray.push(item.id);
        });
    }

    filtrar_estaciones(estaciones) {
        this.loading = true;
        // Quitar si existe la capa y la fuente
        if(this.map.getLayer('clusters')) this.map.removeLayer('clusters');
        if(this.map.getLayer('cluster-count')) this.map.removeLayer('cluster-count');
        if(this.map.getLayer('points')) this.map.removeLayer('points');
        if(this.map.getSource('points')) this.map.removeSource('points');
        this.estaciones = []; this.estaciones_lista = []; this.estaciones_mantencion_lista = []; this.estacion = false;
        let continuar = false;

        //Obtener ids relacionados al tipo de combustible seleccionado
        let idsCombustibles = [];
        idsCombustibles.push(parseInt(this.combustible_storage));

        let combSeleccionado = this.combustiblesList.find(comb => comb.id == this.combustible_storage);

        //Añadiendo otro id que coincida con el mimo nombre largo del combustible
        this.combustiblesList.forEach(comb => {
          if (comb.nombre_largo == combSeleccionado.nombre_largo && comb.id !== combSeleccionado.id) idsCombustibles.push(comb.id);
        })

        estaciones.forEach((item, index) => {
            continuar = false;
            if(this.marcasSeleccionadasArray.length > 0) {
                if(this.marcasSeleccionadasArray.indexOf(item.marca) >= 0) { continuar = true; }
            }
            else { continuar = true; }

            if(continuar) {
                continuar = true;
                if(this.serviciosSeleccionadosArray.length > 0) {
                    let existe = false;
                    this.serviciosSeleccionadosArray.forEach((itemS, indexS) => {
                        existe = false;
                        item.servicios.forEach((itemIs, indexIs) => {
                            if(itemS == itemIs.id) existe = true;
                        });
                        if(!existe) {
                            continuar = false;
                            return;
                        }
                    });
                } else { continuar = true; }
                if(continuar) {
                    continuar = false;

                    idsCombustibles.forEach(idServicio => {
                      item.combustibles.some(itemC => {
                        if (idServicio == itemC.id && itemC.precio > 0) {
                          continuar = true;
                          item['combustible' + itemC['tipo_atencion']] = {'id': itemC['id'], 'nombre_corto': itemC['nombre_corto'], 'unidad_cobro': itemC['unidad_cobro'], 'precio': parseFloat(itemC['precio']), 'precio_fecha': itemC['precio_fecha'], 'tipo_atencion': itemC['tipo_atencion']};
                        }
                      });
                    });

                    // item.combustibles.some(itemC => {
                    //     if(idsCombustibles.includes(itemC.id) && itemC.precio > 0) {
                    //         continuar = true;
                    //         item['combustible'] = {'id': itemC['id'], 'nombre_corto': itemC['nombre_corto'], 'unidad_cobro': itemC['unidad_cobro'], 'precio': parseFloat(itemC['precio']), 'precio_fecha': itemC['precio_fecha'], 'tipo_atencion': itemC['tipo_atencion']};
                    //         item['id_combustibles'] = idsCombustibles;
                    //         return true;
                    //     }
                    // });

                    item.combustibles.some(itemC => {
                        if(idsCombustibles.includes(itemC.id) && itemC.precio > 0) {
                            continuar = true;
                            item['combustible'] = {'id': itemC['id'], 'nombre_corto': itemC['nombre_corto'], 'unidad_cobro': itemC['unidad_cobro'], 'precio': parseFloat(itemC['precio']), 'precio_fecha': itemC['precio_fecha'], 'tipo_atencion': itemC['tipo_atencion']};
                            item['id_combustibles'] = idsCombustibles;
                            return true;
                        }
                    });
                    if(continuar) this.estaciones.push(item);
                }
            }
        });

        /*Obtengo el primer elemento para saber en cual tipo de combustible quedó en storage,
        * para así poder cambiar el texto en el boton del menú*/
        if(this.estaciones.length){
            const tipoCombustible = this.estaciones[0]['combustible']['nombre_corto'];
            this.cambiarTextoMenu(tipoCombustible);

        }

        // Obtener el combustible si se ha realizado una búsqueda previa en el navegador y utilizar para realizar búsqueda
        if(localStorage.getItem('busqueda_estaciones_latitud')) {
            this.longitud_storage = +localStorage.getItem('busqueda_estaciones_longitud');
            this.latitud_storage = +localStorage.getItem('busqueda_estaciones_latitud');
            this.setearMapa();
        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    this.longitud_storage = position.coords.longitude;
                    this.latitud_storage = position.coords.latitude;
                    this.setearMapa(this.longitud_storage, this.latitud_storage);
                }, error => {
                    localStorage.setItem('busqueda_estaciones_longitud', this.longitud_defecto);
                    localStorage.setItem('busqueda_estaciones_latitud', this.latitud_defecto);
                    this.longitud_storage = +localStorage.getItem('busqueda_estaciones_longitud');
                    this.latitud_storage = +localStorage.getItem('busqueda_estaciones_latitud');
                    this.setearMapa();
                });
            } else {console.log('No geolocation habilitada');}
        }
    }

    cerrarState() {
        this.state = false;
    }

    onMarcarLikeDislikeEnListado(param) {
        this.estaciones.some(item => {
            // Cambiar like/dislike contador en el listado de estaciones
            if(item.id == param.id) {
                if(item.usuario_likes === null) {           // Si el usuario no tiene registrado like o dislike
                    if(param.like) {                        // Guardar y sumar un like
                        item.usuario_likes = true;
                        item.likes = item.likes + 1;
                    }
                    if(!param.like) {                       // Guardar y sumar un dislike
                        item.usuario_likes = false;
                        item.dislikes = item.dislikes + 1;
                    }
                } else if(item.usuario_likes == false) {    // Si el usuario tiene registrado dislike
                    if(param.like) {                        // Guardar y sumar un like y restar un dislike
                        item.usuario_likes = true;
                        item.likes = item.likes + 1;
                        item.dislikes = item.dislikes - 1;
                    }
                    if(!param.like) {                       // Guardar y restar dislike
                        item.usuario_likes = null;
                        item.dislikes = item.dislikes - 1;
                    }
                } else if(item.usuario_likes == true) {     // Si el usuario tiene registrado like
                    if(param.like) {                        // Guardar y restar like
                        item.usuario_likes = null;
                        item.likes = item.likes - 1;
                    }
                    if(!param.like) {                       // Guardar y restar like y sumar un dislike
                        item.usuario_likes = false;
                        item.likes = item.likes - 1;
                        item.dislikes = item.dislikes + 1;
                    }
                }
                return true;
            }
        });
    }

    cerrarMenu() {
        let element = document.getElementById("veloFull");
        element.classList.remove("show");
        element = document.getElementById("veloFullNav");
        element.classList.remove("show");
    }

    activarFiltroCombustible(idCombustible,e){
        if(localStorage.getItem('busqueda_estaciones_combustible') == idCombustible) {return false;}

        $(".btn-app").removeClass('active');
        $(e.target).parent().addClass('active');

        const textoCombustible = $(e.target).text();
        this.cambiarTextoMenu(textoCombustible);
        this.onSubmitBusqueda({'combustible':idCombustible});
    }

    cambiarTextoMenu(tipoCombustible){
        $(".text-menu").text(tipoCombustible);
    }

    onModalClose() {
        this.estacion = false;
    }
}
