import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Config } from "../../app.config";
import { EstacionService } from '../../services/estacion.service';
import { ZonaService } from '../../services/zona.service';
import { ComunaService } from '../../services/comuna.service';
import { ToastrService } from 'ngx-toastr';
import { SocialService } from "ngx-social-button";
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-zonal',
  templateUrl: './zonal.component.html',
  styleUrls: ['./zonal.component.css']
})
export class ZonalComponent implements AfterViewInit, OnDestroy, OnInit {
    configuraciones;
    loading:boolean = false;
    zonas = [];
    estaciones = []; regiones = [];
    fecha_hora_actual:any;
    state: any;

    /* Compartir */
    text:string = "";
    url:string = "";
    shareObj = {
        href: "",
        hashtag:""
    };

    dtOptions: DataTables.Settings = {};
    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<any> = new Subject();
    groupColumn = 0;

    url_descarga_pdf:string = "";
    url_descarga_excel:string = "";

    @ViewChild(DataTableDirective, {static: false})
    datatableElement: DataTableDirective;
    
    constructor(public config: Config, private toastr: ToastrService, public estacionService: EstacionService, 
        public zonaService: ZonaService, public comunaService: ComunaService, private route: ActivatedRoute, private socialAuthService: SocialService) { }

    ngOnInit() {
        this.loading = true;
        this.configuraciones = this.config.data;
        this.fecha_hora_actual = new Date();

        this.cerrarMenu();

        this.url = this.configuraciones.compartir.url_reporte_nacional;
        this.text = "Bencina en Línea - Reporte Nacional";
        this.shareObj = {
            href: this.configuraciones.compartir.url_reporte_nacional,
            hashtag:""
        };

        this.url_descarga_pdf = this.configuraciones.api.reportes.base+this.configuraciones.api.reportes.nacional.pdf;
        this.url_descarga_excel = this.configuraciones.api.reportes.base+this.configuraciones.api.reportes.nacional.excel;
        
        // Obtener todas las zonas geográficas para el acordeon
        this.zonaService.getZonas()
            .subscribe(zonas => {
                if(zonas) {
                    if(zonas.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener las zonas geográficas.', '');
                        this.zonas = [];
                    } else if(zonas) {
                        this.zonas = zonas.data;
                    }
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener las zonas geográficas.', '');
                    this.loading = false;
                }
            });

        this.loading = true;
        // Obtener los nombres de regiones para la zona   
        this.comunaService.getRegiones()
            .subscribe(regiones => {
                if(regiones) {
                    this.regiones = regiones.data;
                } else {
                    this.state = [{state: "error", origen: "reporte_comunal"}];
                    this.loading = false;
                }
            });
        this.loading = true;
        this.obtenerReporte();
        
        this.dtOptions = {
            paging: false,  // Quitar paginación, mostrar todo
            searching: false,   // Quitar campo para buscar
            order: [[ this.groupColumn, 'asc' ]],
            columnDefs: [ { orderable: false, targets: '_all' }, {width: "17%", targets: [6]} ],   // Deshabilitar orden para todas las columnas
            responsive: true,   // Use this attribute to enable the responsive extension
            info: false,
            language: {
                zeroRecords: "Sin datos",
                emptyTable: "Sin datos"
            },
            drawCallback: () => {
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {      
                    const self = this;  
                    var last=null;
                    var rows = dtInstance.rows({page:'current'}).nodes();
                    let zonas = this.zonas; let regiones_data = this.regiones;

                    // Generar la fila de grupo/zona
                    dtInstance.column(this.groupColumn, {page:'current'}).data().each(function (group, i) {
                        if (last !== group) {
                            let nombre_grupo_zona = ""; let zona_id = 0;
                            zonas.some(item => {
                                if(item.id == group) {
                                    nombre_grupo_zona = item.nombre; zona_id = item.id;
                                    return true;
                                }
                            });
                            let lista_regiones_zona = "";
                            regiones_data.forEach((item, index) => {
                                if(item.zona_geografica_id == zona_id) {
                                    lista_regiones_zona = (lista_regiones_zona == "" ? item.nom_region : lista_regiones_zona+", "+item.nom_region);
                                }
                            });
                            $(rows).eq(i).before('<tr class="group"><td colspan="5"><div class="row"><div class="col col-4"><div class="row"><h3 class="col col-12">'+nombre_grupo_zona+'</h3><div class="col col-12 regiones-zona">'+lista_regiones_zona+'</div></div></div><div class="col text-right"><img src="/assets/img/mapa_zona_'+zona_id+'.png"></img></div></td></tr>');
                            last = group;
                        }
                    });
                });
            }
        };
                    
    }
    ngAfterViewInit(): void { this.dtTrigger.next(); }

    ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }

    obtenerReporte() {
        // Obtener todas las estaciones para el reporte
        this.estacionService.getReporteNacional()
            .subscribe(estaciones => {
                if(estaciones) {
                    if(estaciones.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener el reporte.', '');
                        this.estaciones = [];
                    } else if(estaciones) {
                        estaciones.data.forEach((estacion, index) => {
                            let regiones = [];
                            this.regiones.forEach((region, index2) => {
                                if(region.zona_geografica_id == estacion.zona_geografica_id) {
                                    regiones.push(region.cod_region);
                                }
                            });
                            estacion['regiones'] = regiones.toString();
                        });
                        this.estaciones = estaciones.data;
                    }
                    // Calling the DT trigger to manually render the table
                    //this.dtTrigger.next();
                    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                      // Destroy the table first
                      dtInstance.destroy();
                      // Call the dtTrigger to rerender again
                      this.dtTrigger.next();
                    });
                    this.loading = false;
                } else {
                    this.toastr.error('Ha ocurrido un error al intentar obtener el reporte.', '');
                    this.loading = false;
                }
            });
    }

    facebookSharing(){
        this.shareObj['url'] = this.url;
        this.socialAuthService.facebookSharing(this.shareObj);
    }

    cerrarMenu() {
        let element = document.getElementById("veloFull");
        element.classList.remove("show");
        element = document.getElementById("veloFullNav");
        element.classList.remove("show");
    }

}

