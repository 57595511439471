import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZonaService {
    private apiUrl:string = '';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(public config: Config, private http: HttpClient) { 
        this.apiUrl = config.data.api.url;
    }

    getZonas(): Observable<any> {
        const url = `${this.apiUrl}zonas`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((regiones) => console.log('getZonas')),
                         catchError(this.handleError('getZonas'))
                   );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
    }
}
