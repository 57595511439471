import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Config } from "../app.config";

import { AuthService } from '../auth/auth.service';
import { UsuarioService } from '../services/usuario.service';
import { ComunaService } from '../services/comuna.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.css']
})
export class MisDatosComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();
    
    configuraciones;
    token_min:number = 0; token_max:number = 1; contrasena_min: number = 1;
    usuario: any;
    editar_nombre: boolean = false; editar_comuna: boolean = false; editar_contrasena: boolean = false; editar_correo:boolean = false; validar_token:boolean = false; mostrar_state:boolean = false;
    nombreForm: FormGroup; comunaForm: FormGroup; contrasenaForm: FormGroup; correoForm: FormGroup; tokenForm: FormGroup;
    correo_validacion: string = "";
    state: any;
    loading:boolean = false;
    regiones: any; comunas: any;

    constructor(public config: Config, public authService: AuthService, private formBuilder: FormBuilder, public usuarioService: UsuarioService, public comunaService: ComunaService, public appComponent: AppComponent) {
        this.configuraciones = this.config.data; this.token_min = this.configuraciones.general.token_min; this.token_max = this.configuraciones.general.token_max; this.contrasena_min = this.configuraciones.general.contrasena_min;
    }

    ngOnInit() { 
        this.eventoPadreComponent.emit();
        this.loading = true;
        this.obtenerDatosUsuario();
    }

    obtenerDatosUsuario() {        
        this.usuarioService.getUsuario()
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.mostrar_state = true;
                        this.state = [{state: "error", origen: "mis_datos", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.usuario = {nombres: usuario.data.nombres, apellidos: usuario.data.apellidos, cod_region: "", region: "", cod_comuna: "", comuna: "", correo: usuario.data.correo, clave_unica: (usuario.data.perfil == this.configuraciones.api.perfil_ciudadano ? false : true)};
                        this.obtenerRegionYComuna(usuario.data.region, usuario.data.comuna);

                        this.nombreForm = this.formBuilder.group({
                            nombres: [this.usuario.nombres.toLowerCase(), Validators.required ],
                            apellidos: [this.usuario.apellidos.toLowerCase(), Validators.required ]
                        });

                        this.comunaForm = this.formBuilder.group({
                            region: ['', Validators.required ],
                            comuna: ['', Validators.required ]
                        });

                        this.contrasenaForm = this.formBuilder.group({
                            contrasena_actual: ['', Validators.required ],
                            contrasena: ['', Validators.required ],
                            confirmacion_contrasena: ['', Validators.required ]
                        });

                        this.correoForm = this.formBuilder.group({
                            correo: [this.usuario.correo, Validators.required]
                        });

                        this.tokenForm = this.formBuilder.group({
                            token: ['', Validators.required]
                        });
                    }
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    private obtenerRegionYComuna(id_region, id_comuna) {
        this.comunaService.getComunas(id_region)
            .subscribe(comunas => {
                if(comunas) {
                    let comuna = this.comunaService.getComunaNombre(comunas, id_comuna);
                    this.usuario.cod_comuna = comuna['cod_comuna'];
                    this.usuario.comuna = comuna['nom_comuna'];
                    this.usuario.cod_region = comuna['cod_region'];
                    this.usuario.region = comuna['nom_region'];
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    onLogout() {
        this.loading = true;
        this.usuarioService.postLogout()
            .subscribe(data => {
                if(data) {
                    if(data.mensaje || data.error) {
                        this.loading = false;
                        this.editar_nombre = false;
                        this.mostrar_state = true;
                        this.state = [{state: "error", origen: "mis_datos", data: [(data.mensaje ? data.mensaje : data.error.mensaje)]}];    
                    } else {
                        this.loading = false;
                        localStorage.removeItem('tipo_token');
                        localStorage.removeItem('api_token');
                        localStorage.removeItem('apellidos_ciudadano_bel');
                        localStorage.removeItem('nombres_ciudadano_bel');
                        this.authService.logout();
                        this.appComponent.revisarCabecera();
                    }
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    onEditarNombre() {
        this.editar_nombre = true;
    }

    onSubmitNombre(nombreForm) {
        this.loading = true;
        this.usuarioService.postModificar(nombreForm)
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.editar_nombre = false;
                        this.mostrar_state = true;
                        this.state = [{state: "error", origen: "mis_datos", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        this.editar_nombre = false;
                        this.mostrar_state = true;
                        this.usuario["nombres"] = nombreForm.nombres;
                        this.usuario["apellidos"] = nombreForm.apellidos;
                        localStorage.setItem('nombres_ciudadano_bel', nombreForm.nombres);
                        localStorage.setItem('apellidos_ciudadano_bel', nombreForm.apellidos);
                        this.appComponent.revisarCabecera();
                        this.state = [{state: "ok", origen: "mis_datos"}];
                    }
                } else {
                    this.loading = false;
                    this.editar_nombre = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    onEditarComuna() {
        this.editar_nombre = false;
        this.loading = true;
        this.comunaService.getRegiones()
            .subscribe(regiones => {
                if(regiones) {
                    this.regiones = regiones;
                    this.obtenerComunaYSetear(this.usuario.cod_region);
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    private obtenerComunaYSetear(id_region) {
        this.comunaService.getComunas(id_region)
            .subscribe(comunas => {
                if(comunas) {
                    this.comunas = comunas;
                    this.comunaForm.patchValue({
                        region: this.usuario.cod_region,
                        comuna: this.usuario.cod_comuna
                    });
                    this.loading = false;
                    this.editar_comuna = true;
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    obtenerComunas(e) {
        let id_region = e.target.value;
        this.loading = true;
        this.comunaService.getComunas(id_region)
            .subscribe(comunas => {
                if(comunas) {
                    this.loading = false;
                    this.comunas = comunas;
                    this.comunaForm.patchValue({
                        comuna: ""
                    });
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    onSubmitComuna(comunaForm) {
        this.loading = true;
        this.usuarioService.postModificarComuna(comunaForm)
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.editar_comuna = false;
                        this.mostrar_state = true;
                        this.state = [{state: "error", origen: "mis_datos", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        this.editar_comuna = false;
                        this.mostrar_state = true;
                        this.state = [{state: "ok", origen: "mis_datos"}];
                    }
                } else {
                    this.loading = false;
                    this.editar_comuna = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    onEditarContrasena() {
        this.editar_nombre = false;
        this.editar_comuna = false;
        this.editar_contrasena = true;   
    }

    onSubmitContrasena(contrasenaForm) {
        this.loading = true;
        this.usuarioService.postModificarContrasenaCuenta(contrasenaForm)
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.editar_contrasena = false;
                        this.mostrar_state = true;
                        this.state = [{state: "error", origen: "mis_datos", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        this.editar_contrasena = false;
                        this.mostrar_state = true;
                        this.state = [{state: "ok", origen: "mis_datos"}];
                    }
                } else {
                    this.loading = false;
                    this.editar_contrasena = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "mis_datos"}];
                }
            });
    }

    onEditarCorreo(correoForm) {
        this.editar_nombre = false;
        this.editar_comuna = false;
        this.editar_contrasena = false;
        this.editar_correo = true;
    }

    onSubmitCorreo(correoForm) {
        this.validar_token = true;
        this.usuario["correo"] = correoForm.correo;
        this.correo_validacion = correoForm.correo;
    }

    onSubmitToken(tokenForm) {
        this.editar_correo = false;
        this.validar_token = false;
        this.mostrar_state = true;
        this.state = [{state: "ok", origen: "mis_datos"}];
    }

    onCancelar() {
        this.editar_nombre = false;
        this.editar_comuna = false;
        this.editar_contrasena = false;   
        this.editar_correo = false;
        this.mostrar_state = false;
        this.validar_token = false;
        this.obtenerDatosUsuario();
    }

}
