import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ReporteVariacionPrecio } from '../dto/ReporteVariacionPrecio.dto';


@Injectable({
  providedIn: 'root'
})
export class ReporteService {  
  private apiUrl: string = '';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(public config: Config, private http: HttpClient) {
      this.apiUrl = config.data.api.url + 'reporte'
  }

  public fechasReporteVariacionPrecioFavoritas(): Observable<any> {
    const url = `${this.apiUrl}/con-cambio-precio-favoritas`;
    let httpOptions;
    if(localStorage.getItem('api_token') && localStorage.getItem('api_token') != '') {
        httpOptions = {
          headers: new HttpHeaders({ 
            'Content-Type': 'application/json', 
            'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token') })
        };
    } else {
        httpOptions = this.httpOptions;
    }
    return this.http.get(url, httpOptions);
  }

  public datosReporteVariacionPrecio(fechaReporte: string): Observable<any> {
    const url = `${this.apiUrl}/con-cambio-precio/datos/${fechaReporte}`;
    let httpOptions;
    if (localStorage.getItem('api_token') && localStorage.getItem('api_token') !== '') {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('tipo_token') + ' ' + localStorage.getItem('api_token') })
        };
    } else {
        httpOptions = this.httpOptions;
    }
    return this.http.get<ReporteVariacionPrecio[]>(url, httpOptions);
  }

  public descargaReporteVariacionPrecio(fechaReporte: string): any {
    const url = `${this.apiUrl}/con-cambio-precio/xls/${fechaReporte}`;
    let httpOptions;
    if(localStorage.getItem('api_token') && localStorage.getItem('api_token') != '') {
        httpOptions = {
          headers: new HttpHeaders({
            'Authorization': localStorage.getItem('tipo_token')+' '+localStorage.getItem('api_token'),
            'Access-Control-Expose-Headers': 'Content-Disposition'
          })
        };
    } else {
        httpOptions = this.httpOptions;
    }
    httpOptions.responseType ='blob';
    httpOptions.observe = 'response';
    return this.http.get(url,httpOptions).subscribe(response =>  this.downLoadFile(response, "application/ms-excel"));
  }

  downLoadFile(data: any, type: string) {
    const contentDisposition = data.headers.get('content-disposition');
    let filename;
    if (contentDisposition.includes('filename*=utf-8\'\'')) {
      filename = decodeURIComponent(contentDisposition.split(';')[2].split('filename*=utf-8\'\'')[1].trim().replace(/"/g, ''));
    }
    else {
      filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
    }

    const blob = new Blob([data.body], { type: type});
    saveAs(blob, filename);
}
   private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
   }
}
