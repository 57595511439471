import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { GeoJSONSourceRaw, MapboxGeoJSONFeature } from 'mapbox-gl';
import { ToastrService } from 'ngx-toastr';
import { Config } from "../app.config";
import { EstacionService } from '../services/estacion.service';
import { MarcaService } from '../services/marca.service';
import { CombustibleService } from '../services/combustible.service';

@Component({
  selector: 'app-busqueda-electrolineras',
  templateUrl: './busqueda-electrolineras.component.html',
  styleUrls: ['./busqueda-electrolineras.component.css']
})
export class BusquedaElectrolinerasComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    loading:boolean = false;
    estaciones = []; estaciones_lista = []; estacion:any = false;
    marcasList = []; marcas_logos:any;
    markers = [];

    map: mapboxgl.Map;
    style = '';
    mapZoom = 0;
    latitud_storage = 0; longitud_storage = 0;
    latitud_defecto = ''; longitud_defecto ='';
    ver_mapa:boolean = true; ver_estacion:boolean = false; ver_resultados:boolean = false;  // Estas banderas se utilizan para la versión movil

    combustibles = [];

  	constructor(public config: Config, private estacionService: EstacionService, private marcaService: MarcaService, private toastr: ToastrService,public combustibleService: CombustibleService) { }

    ngOnInit() {
        this.loading = true;
        this.configuraciones = this.config.data;
        this.longitud_defecto = this.configuraciones.map.longitud_defecto;
        this.latitud_defecto = this.configuraciones.map.latitud_defecto;

        (mapboxgl as any).accessToken = this.configuraciones.map.token;
        this.mapZoom = this.configuraciones.map.zoom;
        this.style = this.configuraciones.map.style;

        // Inicializar el mapa
        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style
        });

        // Add geocoder control to the map.
        var geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Ingresar dirección',
            countries: 'cl'
        });

        // Add geocoder control to the map.
        var geocoder_movil = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Ingresar dirección',
            countries: 'cl'
        });

        document.getElementById('geocoder').appendChild(geocoder.onAdd(this.map));
        document.getElementById('geocoder-movil').appendChild(geocoder_movil.onAdd(this.map));

        // Guardar coordenadas y guardar en storage
        geocoder.on('result', function(result) {
            let element = document.getElementById("veloFull");
            element.classList.remove("show");
            element = document.getElementById("veloFullNav");
            element.classList.remove("show");

            this.longitud_storage = result.result.geometry.coordinates[0];
            this.latitud_storage = result.result.geometry.coordinates[1];
            localStorage.setItem('busqueda_electrolineras_longitud', this.longitud_storage);
            localStorage.setItem('busqueda_electrolineras_latitud', this.latitud_storage);
        });

        // Al seleccionar una dirección en móvil debemos cerrar el panel de filtros
        geocoder_movil.on('result', function(result) {
            this.longitud_storage = result.result.geometry.coordinates[0];
            this.latitud_storage = result.result.geometry.coordinates[1];
            localStorage.setItem('busqueda_electrolineras_longitud', this.longitud_storage);
            localStorage.setItem('busqueda_electrolineras_latitud', this.latitud_storage);
            $(".collapse.show").removeClass("show");
        });


      this.combustibleService.getCombustibles()
        .subscribe(combustibles => {
          if(combustibles) {
            if(combustibles.mensaje) {
              this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
              this.loading = false;
            } else if(combustibles) {
              this.combustibles = combustibles.data;
              //console.log("this.combustibles",this.combustibles);

            }
          } else {
            this.toastr.error('Ha ocurrido un error al intentar obtener los combustibles.', '');
            this.loading = false;
          }
        });

      /*Crea evento para abrir o cerrar menu de bencinas*/
      $(".menu-toggle").on("click",function(){
        $(".menu-toggle").toggleClass('open');
        $(".menu-line").toggleClass('open');
        /*Cambio el contenido del boton del menu*/
        if($(".menu-toggle").hasClass('open')){
          $('.text-menu').addClass('d-none');
          $('.icon-menu').removeClass('d-none');
        }else{
          $('.text-menu').removeClass('d-none');
          $('.icon-menu').addClass('d-none');
        }

      });

    }
    ngAfterViewInit() {
        this.obtenerListadoApi();
        this.map.resize();
    }

    obtenerListadoApi() {
        this.estaciones = []; this.estaciones_lista = []; this.estacion = false;
        // Obtener todas las marcas para el filtro
        this.marcaService.getMarcas()
            .subscribe(marcas => {
                if(marcas) {
                    if(marcas.mensaje) {
                        this.toastr.error('Ha ocurrido un error al intentar obtener las marcas.', '');
                        this.loading = false;
                    } else if(marcas) {
                        this.marcas_logos = [];
                        this.marcasList = marcas.data;
                        this.marcasList.forEach((marca, index) => {
                            this.marcas_logos[marca.id] = {logo: marca.logo, nombre: marca.nombre};
                        });

                        // Obtener listado de estaciones de servicio
                        this.estacionService.getElectrolineras()
                            .subscribe(estaciones => {
                                if(estaciones) {
                                    if(estaciones.mensaje) {
                                        this.loading = false;
                                        this.toastr.error('Ha ocurrido un problema al intentar obtener las Electrolineras.', '');
                                    } else if(estaciones) {
                                        this.estaciones = estaciones.data;

                                        // Obtener el combustible si se ha realizado una búsqueda previa en el navegador y utilizar para realizar búsqueda
                                        if(localStorage.getItem('busqueda_electrolineras_latitud')) {
                                            this.longitud_storage = +localStorage.getItem('busqueda_electrolineras_longitud');
                                            this.latitud_storage = +localStorage.getItem('busqueda_electrolineras_latitud');
                                            this.setearMapa();
                                        } else {
                                            if (navigator.geolocation) {
                                                navigator.geolocation.getCurrentPosition(position => {
                                                    this.longitud_storage = position.coords.longitude;
                                                    this.latitud_storage = position.coords.latitude;
                                                    this.setearMapa(this.longitud_storage, this.latitud_storage);
                                                }, error => {
                                                    localStorage.setItem('busqueda_electrolineras_longitud', this.longitud_defecto);
                                                    localStorage.setItem('busqueda_electrolineras_latitud', this.latitud_defecto);
                                                    this.longitud_storage = +localStorage.getItem('busqueda_electrolineras_longitud');
                                                    this.latitud_storage = +localStorage.getItem('busqueda_electrolineras_latitud');
                                                    this.setearMapa();
                                                });
                                            } else {console.log('No geolocation habilitada');}
                                        }
                                    }
                                }
                            });
                    }
                }
            });
    }

    setearMapa(usuario_longitud = 0, usuario_latitud = 0) {
        this.map.setZoom(this.mapZoom);

        // Si se obtuvo ubicación del usuario mostrar marcador en el mapa
        if(usuario_longitud != 0) {
            this.map.setCenter([usuario_longitud, usuario_latitud]);
            let el = document.createElement('div');
            let html = '<img src="/assets/img/map-marker-alt-solid.svg"></img>';
            el.innerHTML = html;
            el.className = 'marker-usuario-ubicacion';

            // add marker to map
            new mapboxgl.Marker(el)
                        .setLngLat([usuario_longitud, usuario_latitud])
                        .addTo(this.map);
        } else {
            this.map.setCenter([this.longitud_storage, this.latitud_storage]);
        }

        let geojson = {
                        "type": "FeatureCollection",
                        "features": [ ]
                    };
        // Desde la data obtenida de la api generar data para formar el layer en el mapa
        this.estaciones.forEach((estacion, index) => {
            //let logo = (this.marcas_logos[estacion.marca] ? this.marcas_logos[estacion.marca]['logo'] : "");
            let logo = estacion.logo;
            geojson['features'].push({"type": "Feature",
                                    "geometry": {
                                        "type": "Point",
                                        "coordinates": [estacion.longitud, estacion.latitud]
                                    },
                                    "properties": {
                                        "id": estacion.id,
                                        "nombre": (this.marcas_logos[estacion.marca] ? this.marcas_logos[estacion.marca]['nombre'] : ""),
                                        "logo": logo,
                                        "marca": estacion.marca,
                                        "direccion": estacion.direccion,
                                        "comuna": estacion.comuna,
                                        "region": estacion.region,
                                        "latitud": estacion.latitud,
                                        "longitud": estacion.longitud,
                                        "electrolinera_bandera": estacion.electrolinera_bandera,
                                        "gasolinera_bandera": estacion.gasolinera_bandera,
                                        "usuario_favorito": estacion.usuario_favorito,
                                        "usuario_likes": estacion.usuario_likes,
                                        "likes": estacion.likes,
                                        "dislikes": estacion.dislikes
                                    }});
            // Agregar layer en mapa para las estaciones para así luego poder obtener los puntos que se ven en pantalla
            if(this.map.getLayer('points')) this.map.removeLayer('points');
            if(this.map.getSource('points')) this.map.removeSource('points');

            let source:any = { type: 'geojson', data: geojson };
            this.map.addSource('points', source);
            this.map.addLayer({
                "id": "points",
                "type": "line",
                "source": "points",
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                    "paint": {
                    "line-color": "#ff69b4",
                    "line-width": 1
                }
            });
        });

        this.map.on('load', loadmap => {
        });
        // Cada vez que se mueve en el mapa o se hace un zoom obtener los marcadores que se ven en pantalla
        this.map.on('idle', idlemap => {
            this.estaciones_lista = [];
            let options_points_in_layer:any = {layers: ['points']};
            let points_in_layer = this.map.queryRenderedFeatures(options_points_in_layer);

            this.removerMarcadores();

            if(!this.ver_estacion) {
                points_in_layer.forEach((point, index) => {
                    this.estaciones_lista.push({
                                                "id": point.properties.id,
                                                "nombre": point.properties.nombre,
                                                "marca": point.properties.marca,
                                                "logo": point.properties.logo,
                                                "direccion": point.properties.direccion,
                                                "comuna": point.properties.comuna,
                                                "region": point.properties.region,
                                                "latitud": point.properties.latitud,
                                                "longitud": point.properties.longitud,
                                                "electrolinera_bandera": point.properties.electrolinera_bandera,
                                                "gasolinera_bandera": point.properties.gasolinera_bandera,
                                                "usuario_favorito": JSON.parse(point.properties.usuario_favorito),
                                                "usuario_likes": JSON.parse(point.properties.usuario_likes),
                                                "likes": point.properties.likes,
                                                "dislikes": point.properties.dislikes,
                                                "en_mantenimiento_bandera": point.properties.en_mantenimiento_bandera
                                            });
                    let el = document.createElement('div');
                    // llamar a api para obtener la imagen del logo de la marca
                    let logobase64 = "";
                    //this.marcaService.getMarcaLogo(point.properties.marca)
                    //                .subscribe(logo => {
                                        let html = "";
                                        if(point.properties.logo) {
                                            logobase64 = point.properties.logo;
                                            html = '<img id="'+point.properties.id+'" class="estacion-logo" src="'+logobase64+'" alt="">';
                                        }
                                        el.innerHTML = html;
                                        el.className = 'marker ';
                                        el.id = point.properties.id;

                                        // add marker to map
                                        let marker = new mapboxgl.Marker(el)
                                                    .setLngLat([point.properties.longitud, point.properties.latitud])
                                                    .addTo(this.map);

                                        this.markers.push(marker);
                                    //});

                });
            }
        });
        // inspect a cluster on click
        this.map.on('click', points => {

            if(!this.ver_estacion && points['originalEvent']['srcElement']['className'] != 'usuario-ubicacion') {
                if(points['originalEvent']['srcElement']['id'] != ''){
                  this.onSeleccionarEstacion(points['originalEvent']['srcElement']['id']);
                }
            }
        });
        this.loading = false;
    }

    onSeleccionarEstacion(id) {
        this.loading = true; this.ver_resultados = false;
        this.estacionService.getEstacion(id)
            .subscribe(estacion => {
                if(estacion) {
                    if(estacion.mensaje) {
                        this.loading = false;
                        this.toastr.error('Ha ocurrido un problema al intentar obtener los datos de la Electrolinera.', '');
                    } else if(estacion) {
                        this.estacion = estacion.data;

                        this.map.setCenter([this.estacion.longitud, this.estacion.latitud]);
                        this.map.resize();
                        this.removerMarcadores();

                        let latitud = 0;
                        let longitud = 0;
                        let geojson:any;
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(position => {
                                longitud = position.coords.longitude;
                                latitud = position.coords.latitude;
                                geojson = {
                                    "type": "FeatureCollection",
                                    "features": [
                                        {
                                            "type": "Feature",
                                            "properties": {
                                                "class": "usuario-ubicacion"
                                            },
                                            "geometry": {
                                                "type": "Point",
                                                "coordinates": [longitud, latitud]
                                            }
                                        },
                                        {
                                            "type": "Feature",
                                            "properties": {
                                                "class": "precio-bajo mapboxgl-marker mapboxgl-marker-anchor-center"
                                            },
                                            "geometry": {
                                                "type": "Point",
                                                "coordinates": [this.estacion.longitud, this.estacion.latitud]
                                            }
                                        }
                                    ]
                                };
                                this.setearMarcasEnMapa(geojson);
                                var bbox:any = [[this.estacion.longitud, this.estacion.latitud], [longitud, latitud]];
                                this.map.fitBounds(bbox, {
                                    padding: {top: 200, bottom:200, left: 200, right: 200}
                                });
                                this.ver_mapa = true; this.ver_estacion = true;
                                this.loading = false;
                            }, error => {
                                this.map.setZoom(this.mapZoom);
                                let el = document.createElement('div');
                                // llamar a api para obtener la imagen del logo de la marca
                                let logobase64 = "";
                                this.marcaService.getMarcaLogo(this.estacion.marca)
                                                .subscribe(logo => {
                                                    let html = "";
                                                    if(logo) {
                                                        logobase64 = logo[0];
                                                        html = '<img class="estacion-logo" src="'+logobase64+'" alt="">';
                                                    }
                                                    el.innerHTML = html;
                                                    el.className = 'marker ';

                                                    // add marker to map
                                                    new mapboxgl.Marker(el)
                                                                .setLngLat([this.estacion.longitud, this.estacion.latitud])
                                                                .addTo(this.map);
                                                    this.ver_mapa = true; this.ver_estacion = true;
                                                });
                                this.loading = false;
                            });
                        } else {console.log('No geolocation habilitada');}
                    }
                }
            });
    }

    removerMarcadores() {
        this.markers.forEach((marker, index) => {
            marker.remove();
        });
    }

    setearMarcasEnMapa(geojson) {
        geojson.features.forEach((marker, index) => {
            let el = document.createElement('div');
            let html = "";
            if(marker.properties.class == "usuario-ubicacion") {
                html = '<img class="text-danger" src="/assets/img/map-marker-alt-solid.svg"></img>';
                el.innerHTML = html;
                el.className = 'marker-'+ marker.properties.class;
                el.id = marker.properties.id;
            } else {
                // llamar a api para obtener la imagen del logo de la marca
                let logobase64 = "";
                this.marcaService.getMarcaLogo(this.estacion.marca)
                                .subscribe(logo => {
                                    let html = "";
                                    if(logo) {
                                        logobase64 = logo[0];
                                        html = '<img id="'+this.estacion.id+'" class="estacion-logo" src="'+logobase64+'" alt="">';
                                    }
                                    el.innerHTML = html;
                                    el.className = 'marker '+ marker.properties.class;
                                    el.id = marker.properties.id;
                                });
            }

            // add marker to map
            new mapboxgl.Marker(el)
                        .setLngLat(marker.geometry.coordinates)
                        .addTo(this.map);
        });
    }

    volverAListado() {
        this.ver_estacion = false;
        this.obtenerListadoApi();
    }

    usarUbicacionUsuario() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.longitud_storage = position.coords.longitude;
                this.latitud_storage = position.coords.latitude;
                localStorage.setItem('busqueda_electrolineras_longitud', this.longitud_storage.toString());
                localStorage.setItem('busqueda_electrolineras_latitud', this.latitud_storage.toString());
                this.setearMapa(this.longitud_storage, this.latitud_storage);
            }, error => {
                this.toastr.error('El navegador no tiene permisos para localizarte.', '');
            });
        } else {console.log('No geolocation habilitada');}
    }

    activarFiltroCombustible(idCombustible,e){
      if(localStorage.getItem('busqueda_estaciones_combustible') == idCombustible) {return false;}

      $(".btn-app").removeClass('active');
      $(e.target).parent().addClass('active');

      localStorage.setItem('busqueda_estaciones_combustible', idCombustible);
      window.location.href = "/#/";
    }
}
