import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CombustibleService {
    private apiUrl:string = '';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(public config: Config, private http: HttpClient) { 
        this.apiUrl = config.data.api.url+"combustible";
    }

    getCombustible(id): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        //return of({ id: 1, nombre_corto: '93', nombre_largo: 'Gasolina 93' });
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((combustibles) => console.log('getCombustible')),
                         catchError(this.handleError('getCombustible'))
                   );
    }

    getCombustibles(): Observable<any> {
        const url = `${this.apiUrl}_ciudadano`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((combustibles) => console.log('getCombustibles')),
                         catchError(this.handleError('getCombustibles'))
                   );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
    }
}
