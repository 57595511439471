import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Config } from "../app.config";

import { AuthService } from '../auth/auth.service';
import { UsuarioService } from '../services/usuario.service';
import { ComunaService } from '../services/comuna.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    token_min:number = 0; token_max:number = 1; contrasena_min: number = 1;
    rol_ciudadano: number = 0;
    registroForm: FormGroup;
    validarForm: FormGroup;
    validar_registro: boolean = false; validar_token: boolean = false; mostrar_state: boolean = false;
    correo_invitacion: string = "";
    state: any;
    api_token:any;
    loading:boolean = false;
    regiones: any; comunas: any;

    constructor(public config: Config, private formBuilder: FormBuilder, public authService: AuthService, public usuarioService: UsuarioService, public comunaService: ComunaService) {
        
    }

    ngOnInit() {
        localStorage.removeItem('tipo_token');
        localStorage.removeItem('api_token');
        localStorage.removeItem('apellidos_ciudadano_bel');
        localStorage.removeItem('nombres_ciudadano_bel');
        this.eventoPadreComponent.emit();
        this.configuraciones = this.config.data; this.token_min = this.configuraciones.general.token_min; this.token_max = this.configuraciones.general.token_max; this.contrasena_min = this.configuraciones.general.contrasena_min;
        this.rol_ciudadano = this.configuraciones.api.rol_ciudadano;
        this.registroForm = this.formBuilder.group({
            email: ['', Validators.required ]
        });

        this.validarForm = this.formBuilder.group({
            token: ['', Validators.required ],
            nombres: ['', Validators.required ],
            apellidos: ['', Validators.required ],
            region: ['', Validators.required ],
            comuna: ['', Validators.required ],
            contrasena: ['', Validators.required ],
            repetir_contrasena: ['', Validators.required ]
        });

        this.authService.isRegistrando();
    }

    onSubmitRegistro(registroForm) {
        this.correo_invitacion = registroForm.email;
        let data = {email: registroForm.email, rol: this.rol_ciudadano};    // Se envía rol de cidadano que al parecer es necesario para la api
        this.loading = true;
        this.usuarioService.postRegistro(data)
            .subscribe(nuevoUsuario => {
                if(nuevoUsuario) {
                    if(nuevoUsuario.mensaje) {
                        this.loading = false;
                        this.validar_registro = true;
                        this.mostrar_state = true;
                        this.state = [{state: "error", origen: "registro", data: [nuevoUsuario.mensaje]}];    
                    } else if(nuevoUsuario.data) {
                        this.api_token = nuevoUsuario.data.api_token;                        
                        localStorage.setItem('tipo_token', 'Bearer');
                        localStorage.setItem('api_token', nuevoUsuario.data.api_token);
                        this.obtenerRegiones();
                    }
                } else {
                    this.loading = false;
                    this.validar_registro = true;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "registro"}];
                }
            });
    }

    private obtenerRegiones() {
        this.comunaService.getRegiones()
            .subscribe(regiones => {
                if(regiones) {
                    this.regiones = regiones;
                    this.loading = false;
                    this.validar_registro = true;
                } else {
                    this.loading = false;
                    this.validar_registro = true;
                    this.validar_token = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "registro_validar"}];
                }
            });
    }

    obtenerComunas(e) {
        let id_region = e.target.value;
        this.loading = true;
        this.comunaService.getComunas(id_region)
            .subscribe(comunas => {
                if(comunas) {
                    this.loading = false;
                    this.comunas = comunas;
                } else {
                    this.loading = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "registro_validar"}];
                }
            });
    }

    onSubmitValidar(validarForm) {        
        let data = {token_correo: validarForm.token, nombres: validarForm.nombres, apellidos: validarForm.apellidos, region: validarForm.region, comuna: validarForm.comuna, contrasena: validarForm.contrasena, confirmacion_contrasena: validarForm.contrasena};
        this.loading = true;
        this.usuarioService.postCompletarRegistro(data)
            .subscribe(verificadoUsuario => {
                if(verificadoUsuario) {
                    this.loading = false;
                    this.validar_token = true;
                    this.mostrar_state = true;
                    this.state = [{state: "ok", origen: "registro"}];
                } else {
                    this.loading = false;
                    this.validar_token = false;
                    this.mostrar_state = true;
                    this.state = [{state: "error", origen: "registro_validar"}];
                }
            });
    }

    cerrarState() {
        this.mostrar_state = false; this.validar_registro = false; this.validar_token = false;
    }
}
