import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Config } from "../app.config";
import { AuthService } from '../auth/auth.service';
import { UsuarioService } from '../services/usuario.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css']
})
export class CabeceraComponent implements OnInit {

    @Input() set comprobar_sesion(data) {
        this.usuario_logeado = data;
        if(localStorage.getItem('api_token')) {
            this.usuario = {nombres: localStorage.getItem('nombres_ciudadano_bel'), apellidos: localStorage.getItem('apellidos_ciudadano_bel')};
        } else {
            this.usuario = {nombres: "", apellidos: ""};
        }
    }
    // Verificar nuevos cambios en nombre al editar desde mis_datos (esta función sólo se llama cuando se edita el nombre desde mis_datos EDIT: también se llama cuando se refresca una página)
    @Input() set comprobar_usuario(data) {
        if(data && data != undefined && localStorage.getItem('api_token')) {
            this.usuario = data; this.usuario_logeado = true;
        } else {
            this.usuario_logeado = false;
        }
    }

    configuraciones;
    usuario_logeado:boolean = false;
    usuario: any;
    mostrar_banner_play_store: boolean = false;
    mostrar_banner_app_store: boolean = false;
    menu_open: boolean = false;
    mostrar_btn_reportes: boolean = false;
    mostrar_btn_login: boolean = false;
    es_home = false;

    constructor(public config: Config, public authService: AuthService, public usuarioService: UsuarioService, private deviceService: DeviceDetectorService, public appComponent: AppComponent, private location: Location) {
        // to print only path eg:"/login"
    }

    ngOnInit() {
        this.configuraciones = this.config.data;
        //this.usuario_logeado = this.authService.isLoggedIn;
        // Si estamos en alguna de estas direcciones no preocuparnos por el usuario conectado y no mostrar el apartado de usuario -> debido a problemas para obtener el estado de sesión real al refrescar una página
        if(this.configuraciones.general.urls_sin_usuario.indexOf(this.location.path()) >= 0) {
            this.usuario = ""; this.usuario_logeado = false;
        }

        //JVP ->Sólo carga navbar abierto cuando este en paágina inicial (solicitan desactivar menu_open)
        // if(this.location.path()=='/busqueda_estaciones'){
        //     this.menu_open=true;
        // }

        const deviceInfo = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        if(this.deviceService.isDesktop()) {
            this.mostrar_banner_app_store = true; this.mostrar_banner_play_store = true; this.mostrar_btn_reportes = true; this.mostrar_btn_login = true;
        } else if(this.deviceService.isMobile() || this.deviceService.isTablet()) {
            switch(deviceInfo.os) {
                case this.configuraciones.so.ios:
                    this.mostrar_banner_app_store = true;
                    break;
                case this.configuraciones.so.android:
                    this.mostrar_banner_play_store = true;
                    break;
                case this.configuraciones.so.mac:
                    this.mostrar_banner_app_store = true;
                    break;
                default:
                    this.mostrar_banner_app_store = true; this.mostrar_banner_play_store = true;
            }
        } else {
            this.mostrar_banner_play_store = true; this.mostrar_banner_app_store = true;
        }
    }

    revisarCabecera() {
        this.usuario_logeado = this.authService.isLoggedIn;
        if(this.usuario_logeado) {
            this.usuario = {nombres: localStorage.getItem('nombres_ciudadano_bel'), apellidos: localStorage.getItem('apellidos_ciudadano_bel')};
        } else {
            this.usuario = {nombres: "", apellidos: ""};
        }
    }

    clickMenu() {
        console.log("click");
        this.menu_open = (this.menu_open ? false : true);
    }

    clickSumenu(e) {
        let element = document.getElementById("veloFull");
        element.classList.remove("show");
        element = document.getElementById("veloFullNav");
        element.classList.remove("show");
        this.menu_open = false;
    }

    clickMenuPanelInfo(e) {
        let panel = document.getElementById("panel-info");
        panel.classList.remove("show");
    }

}
