import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appValidateEqualTo]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EqualTextValidatorDirective, multi: true }]
})

export class EqualTextValidatorDirective implements Validator {
    @Input('appValidateEqualTo') controlNameToEqual: string;

    validate(c: AbstractControl): ValidationErrors | null {
        const controlToEqual = c.root.get(this.controlNameToEqual);
        if(controlToEqual) {
            const subscription: Subscription = controlToEqual.valueChanges.subscribe(() => {
                c.updateValueAndValidity();
                subscription.unsubscribe();
            });
        }
        return controlToEqual && controlToEqual.value !== c.value ? { 'validateEqual': true } : null;
    }
}