import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Config } from "../app.config";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComunaService {
    private apiUrl:string = '';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(public config: Config, private http: HttpClient) { 
        this.apiUrl = config.data.api.url;
    }

    getRegiones(): Observable<any> {
        const url = `${this.apiUrl}region`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((regiones) => console.log('getRegiones')),
                         catchError(this.handleError('getRegiones'))
                   );
    }

    getComunas(id_region:string = ""): Observable<any> {
        const url = `${this.apiUrl}comuna/${id_region}`;
        return this.http.get(url, this.httpOptions)
                   .pipe(tap((comunas) => console.log('getComunas')),
                         catchError(this.handleError('getComunas'))
                   );
    }

    getRegionNombre(regiones, id_region) {
        let data = "";
        regiones.data.forEach((region, index) => {
            if(region.cod_region == `${id_region}`) {
                data = region.nom_region;
            }
        });
        return data;
    }

    getComunaNombre(comunas, id_comuna) {
        let data = "";
        comunas.data.forEach((comuna, key) => {
            if(comuna.cod_comuna == `${id_comuna}`) {
                data = comuna;
            }
        });
        return data;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(`${operation} failed: ${error.message}`); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
    }
}
