import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { UsuarioService } from '../services/usuario.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isLoggedIn = false;
    // store the URL so we can redirect after logging in
    redirectUrl: string;
    redirectLogoutUrl: string = '/login';
    registrando: boolean = false;

    constructor(public usuarioService: UsuarioService, public router: Router, private location: Location) {
        if(localStorage.getItem('api_token')) {
            //this.isLoggedIn = true;
        }
    }

    login() {
        this.isLoggedIn = true;
    }

    logout(): void {
        this.isLoggedIn = false;
        this.router.navigateByUrl(this.redirectLogoutUrl);
    }

    isRegistrando() {
      this.registrando = true;
    }

    usuarioLogeado(session) {
        let path = this.location.path();
        if(!session && path != 'registro' && path != 'clave_unica_registro') {
            this.router.navigateByUrl(this.redirectLogoutUrl);
        }
    }
}