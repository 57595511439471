import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Config } from "../app.config";
import { AuthService } from '../auth/auth.service';
import { UsuarioService } from '../services/usuario.service';
import { ComunaService } from '../services/comuna.service';

@Component({
  selector: 'app-clave-unica',
  templateUrl: './clave-unica.component.html',
  styleUrls: ['./clave-unica.component.css']
})
export class ClaveUnicaComponent implements OnInit {

    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();

    configuraciones;
    token_min:number = 0; token_max:number = 1;
    registroForm: FormGroup;
    validarForm: FormGroup;
	nombre_ciudadano_clave_unica: string = "";
    correo_invitacion:string = "";
    validar_registro: boolean = false;
    validar_token: boolean = false;
    state: any = false;
    loading:boolean = false;
    regiones: any; comunas: any;
  
    constructor(public config: Config, private formBuilder: FormBuilder, private route: ActivatedRoute, public router: Router, public authService: AuthService, public usuarioService: UsuarioService, public comunaService: ComunaService) { }

    onSubmitRegistro(registroForm) {
        this.loading = true;
        this.usuarioService.postRegistroClaveUnica(registroForm)
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.state = [{state: "error", origen: "clave_unica", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        this.correo_invitacion = registroForm.correo;
                        this.validar_registro = true;
                    }
                } else {
                    this.loading = false;
                    this.state = [{state: "error", origen: "clave_unica"}];
                }
            });
    }

    onSubmitValidar(validarForm) {
        this.loading = true;
        this.usuarioService.postVerificarCorreo(validarForm)
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.state = [{state: "error", origen: "clave_unica", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.loading = false;
                        this.validar_token = true;
                        this.state = [{state: "ok", origen: "clave_unica"}];
                    }
                } else {
                    this.loading = false;
                    this.state = [{state: "error", origen: "clave_unica"}];
                }
            });
    }

    ngOnInit() {
        this.configuraciones = this.config.data; this.token_min = this.configuraciones.general.token_min; this.token_max = this.configuraciones.general.token_max;
        this.usuarioService.getUsuario()
            .subscribe(usuario => {
                if(usuario) {
                    if(usuario.mensaje) {
                        this.loading = false;
                        this.state = [{state: "error", origen: "clave_unica", data: [usuario.mensaje]}];    
                    } else if(usuario.data) {
                        this.nombre_ciudadano_clave_unica = usuario.data.nombres+" "+usuario.data.apellidos;
                        this.obtenerRegiones();
                    }
                } else {
                    this.loading = false;
                    this.state = [{state: "error", origen: "clave_unica"}];
                }
            });
        
        this.registroForm = this.formBuilder.group({
            region: ['', Validators.required ],
            comuna: ['', Validators.required ],
            correo: ['', Validators.required ],
            correo_repetir: ['', Validators.required ]
        });

        this.validarForm = this.formBuilder.group({
            token_correo: ['', Validators.required ]
        });
    }

    obtenerRegiones() {
        this.comunaService.getRegiones()
            .subscribe(regiones => {
                if(regiones) {
                    this.regiones = regiones;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.validar_registro = true;
                    this.state = [{state: "error", origen: "clave_unica"}];
                }
            });
    }

    obtenerComunas(e) {
        let id_region = e.target.value;
        this.loading = true;
        this.comunaService.getComunas(id_region)
            .subscribe(comunas => {
                if(comunas) {
                    this.loading = false;
                    this.comunas = comunas;
                } else {
                    this.loading = false;
                    this.state = [{state: "error", origen: "clave_unica"}];
                }
            });
    }
}
