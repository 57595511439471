import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
    
    @Output() eventoPadreComponent: EventEmitter<any> = new EventEmitter();
    state: any;

    constructor() { }

    ngOnInit() { 
        this.state = [{state: "error", origen: "not_found"}];
    }

}
