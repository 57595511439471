import { Component, OnInit } from '@angular/core';
import { Config } from "../app.config";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    configuraciones;

  	constructor(public config: Config) { }

  	ngOnInit() {
  		this.configuraciones = this.config.data;
  	}

}
