import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from "../app.config";

@Component({
    selector: 'app-listado-estaciones',
    templateUrl: './listado-estaciones.component.html',
    styleUrls: ['./listado-estaciones.component.css']
})
export class ListadoEstacionesComponent implements OnInit {

    @Input() combustible = [];
    @Input() estaciones = [];
    @Input() estaciones_mantencion = [];
    @Input() solo_puntos_electricos = 0;
    @Input() solo_favoritos = 0;
    @Input() precio_minimo:number = 0;
    @Input() precio_maximo: number = 0;
    @Input() total_estaciones: number = 0;
    @Output() seleccionarEstacion: EventEmitter<any> = new EventEmitter();
    @Output() mostrarTodasLasEstacionesFavoritas: EventEmitter<any> = new EventEmitter();
    configuraciones;
    iconSort: string = "sort-numeric-down";
    state: any;
    sortBy:string = "combustible.precio";
    sortOrder:string = "asc";

    constructor(public config: Config, public router: Router) { }

    ngOnInit() {
        this.configuraciones = this.config.data;
        if(this.solo_favoritos) {
            this.state = [{state: "empty", origen: "estaciones_favoritas"}];
        } else {
            this.state = [{state: "empty", origen: "busqueda_estaciones"}];
        }
    }

    invertirOrdenLista() {
      this.iconSort = (this.iconSort == "sort-numeric-down" ? "sort-numeric-up-alt" : "sort-numeric-down");
      this.sortOrder = (this.sortOrder == "desc" ? "asc" : "desc");
    }

}
